import omit from 'lodash/omit';

import config from '../../config';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities, ensureAvailabilityException } from '../../util/data';
import { isSameDate, monthIdString } from '../../util/dates';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import axios from '../../services/axios-client';

const { UUID } = sdkTypes;

const location = (winery) => {
  const obj = {
    id: winery?._id,
    slug: winery?.slug,
    name: winery?.name,
    city: winery?.city,
    address: winery?.street,
    state: winery?.state,
    lat: Number(winery?.lat),
    lng: Number(winery?.lng)
  }
  return [obj]
}

// ================ Action types ================ //


export const GET_MY_WINERY_REQUEST = 'app/WineryPageInfo/GET_MY_WINERY_REQUEST';
export const GET_MY_WINERY_SUCCESS = 'app/WineryPageInfo/GET_MY_WINERY_SUCCESS';
export const GET_MY_WINERY_ERROR = 'app/WineryPageInfo/GET_MY_WINERY_ERROR';
export const GET_VARIETALS = 'app/WineryPageInfo/GET_VARIETALS';
export const GET_STATES = 'app/WineryPageInfo/GET_STATES';

// ================ Reducer ================ //

const initialState = {
  winery: null,
  wineryLoading: false,
  wineryError: null,
  states: [],
  varietals: [],
  locations: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    // winery info from express server
    case GET_MY_WINERY_REQUEST:
      return { ...state, wineryLoading: true, wineryError: null, winery: null };
    case GET_MY_WINERY_SUCCESS:
      return { ...state, winery: payload.winery, locations: location(payload.winery), wineryLoading: false, wineryError: null };
    case GET_MY_WINERY_ERROR:
      return { ...state, wineryError: null,  wineryLoading: false, winery: null };
    case GET_VARIETALS:
      return { ...state, varietals: payload.varietals };
    case GET_STATES:
      return { ...state, states: payload.states };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //


export const getMyWineryRequest = () => ({
  type: GET_MY_WINERY_REQUEST
});
export const getMyWinerySuccess = winery => ({
  type: GET_MY_WINERY_SUCCESS,
  payload: {winery}
});
export const getMyWineryError = e => ({
  type: GET_MY_WINERY_ERROR,
  error: true,
  payload: e,
});
export const getVarietals = varietals => ({
  type: GET_VARIETALS,
  payload: { varietals}
});
export const getStates = states => ({
  type: GET_STATES,
  payload: { states}
});


// ================ Thunk ================ //

export const getMyWinery = (userId, sharetribeUserId) => dispatch => {
  dispatch(getMyWineryRequest());
  return axios.get(`winery/my-winery?userId=${userId}&sharetribeUserId=${sharetribeUserId}`)
          .then(data => dispatch(getMyWinerySuccess(data?.data)))
          .catch(err => dispatch(getMyWineryError(err)));
}

export const getAllStates = () => dispatch => {
  return axios.get(`state`)
    .then( data => {
      dispatch(getStates(data.data))
    })
    .catch(err => {
      console.log('err', err);
    });
}

export const getAllVarietals = () => dispatch => {
  return axios.get(`varietal`)
    .then( data => {
      dispatch(getVarietals(data.data))
    })
    .catch(err => {
      console.log('err', err);
    });
}

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = params => (dispatch, getState, sdk) => {
  // get states and varietals for dropdown menu items
  dispatch(getAllVarietals());
  dispatch(getAllStates());

  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      // if (currentUser) {
      //   const userId = currentUser.expresUserId;
      //   const sharetribeUserId = currentUser.id.uuid;
      //   dispatch(getMyWineryRequest());
      //   axios.get(`winery/my-winery?userId=${userId}&sharetribeUserId=${sharetribeUserId}`)
      //     .then(data => dispatch(getMyWinerySuccess(data?.data)))
      //     .catch(err => dispatch(getMyWineryError(err)));
      // }
      return response;
    })
    .catch(e => {
      throw e;
    });
};
