import { toast } from 'react-toastify';
import axios from './axios-client';

export const createExpressUser = async (obj) => {
  try {
    await axios.post(`user/create`, obj);
  } catch (error) {
    toast.error(error.message);
  }
};
export const userProfile = async (userId, obj) => {
  try {
    const res = await axios.put(`user/profile/${userId}`, obj);
    toast.success(res?.data.message);
  } catch (error) {
    console.log('err', error.response);
    toast.error(error?.response.data.message);
  }
};
export const getExpressUser = async (userId) => {
  try {
    const res = await axios.get(`user/get-current-user/${userId}`);
    return res.data;
  } catch (error) {
    toast.error(error?.response.data.message);
  }
};
export const getProfile = async (sharetribeUserId) => {
  try {
    const res = await axios.get(`user/profile/${sharetribeUserId}`);
    return res.data;
  } catch (error) {
    toast.error(error?.response.data.message);
  }
};
