export const addToStorage = wine => {
  const exists = getStorage();
  let wines_in_cart = [];
  if (!exists) {
    wines_in_cart = [wine];
  } else {
    wines_in_cart = JSON.parse(exists);
    if (wines_in_cart.filter(w => w.id == wine.id).length === 0) {
      wines_in_cart = [...wines_in_cart, wine];
    } else {
      wines_in_cart = wines_in_cart.filter(w => w.id != wine.id);
      wines_in_cart = [...wines_in_cart, wine];
    }
  }
  updateStorage(wines_in_cart);
};

export const getStorage = () => localStorage.getItem('wines_in_cart');

export const updateStorage = cart => localStorage.setItem('wines_in_cart', JSON.stringify(cart));

export const removeFromStore = paramWine => {
  const exists = getStorage();
  if (exists) {
    const wines_in_cart = JSON.parse(exists);
    const new_wines_in_cart = wines_in_cart.filter(wine => wine.id !== paramWine);
    updateStorage(new_wines_in_cart);
  }
};

export const getWinesInCart = () => {
  const exists = getStorage();
  return exists ? JSON.parse(exists) : [];
};

export const clearTheCart = () => localStorage.removeItem('wines_in_cart');
