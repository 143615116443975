import config from '../../config';
import { getWineryBySlugForDuckFile } from '../../services/winery';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import imgSrc from './winery.jpg';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

const canonicalRootURL = config.canonicalRootURL;

// ================ Action types ================ //

export const FETCH_WINERY_LISTINGS_REQUEST = 'app/WineryPage/FETCH_WINERY_LISTINGS_REQUEST';
export const FETCH_WINERY_LISTINGS_SUCCESS = 'app/WineryPage/FETCH_WINERY_LISTINGS_SUCCESS';
export const FETCH_WINERY_LISTINGS_ERROR = 'app/WineryPage/FETCH_WINERY_LISTINGS_ERROR';

export const FETCH_WINERY_BY_SLUG_REQUEST = 'app/WineryPage/FETCH_WINERY_BY_SLUG_REQUEST';
export const FETCH_WINERY_BY_SLUG_SUCCESS = 'app/WineryPage/FETCH_WINERY_BY_SLUG_SUCCESS';
export const FETCH_WINERY_BY_SLUG_ERROR = 'app/WineryPage/FETCH_WINERY_BY_SLUG_ERROR';

export const ADD_WINERY_WINES_ENTITIES = 'app/WineryPage/ADD_WINERY_WINES_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  entities: {},
  winery: null,
  wineryLoading: false,
  wineryErrors: null,
  schema: {},
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    entities: updatedEntities({ ...state.entities }, apiResponse),
  };
};

const getFirstImageOfTheWinery = imgs => {
  if (imgs.length > 0) {
    return [`${imgs[0].Location}`];
  } else {
    return [`${canonicalRootURL}${imgSrc}`];
  }
};

// const updateListingAttributes = (state, listingEntity) => {
//   const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
//   const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
//   const ownListingEntities = {
//     ...state.ownEntities.ownListing,
//     [listingEntity.id.uuid]: updatedListing,
//   };
//   return {
//     ...state,
//     ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
//   };
// };

const wineryPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_WINERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_WINERY_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_WINERY_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryListingsError: payload,
      };
    case FETCH_WINERY_BY_SLUG_REQUEST:
      return {
        ...state,
        winery: null,
        wineryLoading: true,
      };
    case FETCH_WINERY_BY_SLUG_SUCCESS:
      return {
        ...state,
        winery: payload.winery,
        schema: payload.schema,
        wineryLoading: false,
      };
    case FETCH_WINERY_BY_SLUG_ERROR:
      return {
        ...state,
        winery: null,
        wineryLoading: false,
        wineryErrors: payload,
      };
    case ADD_WINERY_WINES_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default wineryPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsById = (state, listingIds) => {
  const { entities } = state.WineryPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (winery wines listings data contains different info - e.g. exact location etc.)
export const addEntities = sdkResponse => ({
  type: ADD_WINERY_WINES_ENTITIES,
  payload: sdkResponse,
});

export const queryWineryListingsRequest = queryParams => ({
  type: FETCH_WINERY_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryWineryListingsSuccess = response => ({
  type: FETCH_WINERY_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryWineryListingsError = e => ({
  type: FETCH_WINERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const getWineryBySlugRequest = () => ({
  type: FETCH_WINERY_BY_SLUG_REQUEST,
});
export const getWineryBySlugSuccess = payload => ({
  type: FETCH_WINERY_BY_SLUG_SUCCESS,
  payload,
});
export const getWineryBySlugErrors = payload => ({
  type: FETCH_WINERY_BY_SLUG_ERROR,
  payload,
});

export const getWineryBySlug = slug => async dispatch => {
  try {
    dispatch(getWineryBySlugRequest);
    const winery = await getWineryBySlugForDuckFile(slug);
    if (winery) {
      const siteTitle = config.siteTitle;
      const images = getFirstImageOfTheWinery(winery.photo);
      const imgUrl = getFirstImageOfTheWinery(winery.photo)[0];
      const facebookImages = [
        {
          height: 630,
          name: 'facebook',
          url: imgUrl,
          width: 1200,
        },
      ];
      const twitterImages = [
        {
          height: 314,
          name: 'twitter',
          url: imgUrl,
          width: 600,
        },
      ];
      const title = `${winery.name} - ${winery.state} Winery - Buy ${winery.name} Wines Direct | ${siteTitle}`;
      const description = `${winery?.name} - ${winery?.short_description}`;

      const payload = {
        winery,
        schema: {
          title,
          description,
          facebookImages,
          twitterImages,
          images,
        },
      };

      dispatch(getWineryBySlugSuccess({ ...payload }));
    }
  } catch (error) {
    dispatch(getWineryBySlugErrors(error));
  }
};

// Throwing error for new (loadData may need that info)
export const wineryListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryWineryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, per_page: perPage };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addEntities(response));
      dispatch(queryWineryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryWineryListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { slug } = params;

  let pub_wineryId = '';
  // Get winery by slug
  await dispatch(getWineryBySlug(slug)).then(() => {
    const { WineryPage } = getState();
    const { winery } = WineryPage;

    const { _id } = winery;
    pub_wineryId = _id;
  });

  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  const paramsToGetWineryListings = {
    ...queryParams,
    pub_wineryId,
    // page,
    // perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),

    // include: ['images', 'currentStock'],
    // 'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    // ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    // ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    // 'limit.images': 1,
  };

  return Promise.all([
    dispatch(getWineryBySlug(slug)),
    dispatch(wineryListings(paramsToGetWineryListings)),
  ]);
};
