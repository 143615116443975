import React, { useState, useEffect } from 'react';
import { any, bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ResponsiveImage,
  Button,
} from '../../../components';
//import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;
import css from './TopbarDesktop.module.css';
import ExternalLink from '../../ExternalLink/ExternalLink';
import { BasketIcon } from '../../../assets';
import { BottleIcon } from '../../../assets';
import { DeleteIcon } from '../../../assets';
import { getListingsById, loadData } from '../../../containers/CartPage/CartPage.duck';
import _ from 'lodash';
import { compose } from 'redux';
import { useHistory, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { formatCurrencyMajorUnit, formatMoney } from '../../../util/currency';
import { removeFromStore } from '../../../util/cartStorage';
import config from '../../../config';
import SearchIcon from '../SearchIcon';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    admin,
    wineryOwner,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    wines,
  } = props;
  const [mounted, setMounted] = useState(false);

  const history = useHistory();
  useEffect(() => {
    setMounted(true);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData(undefined, {}));
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isWineryOwner = mounted && isAuthenticated && wineryOwner;
  const isAdmin = mounted && isAuthenticated && admin;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const classes = classNames(rootClassName || css.root, className);

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const cartWines = _.groupBy(wines, w => w?.attributes.publicData.wineryName);
  const [subtotal, setSubtotal] = useState('');
  const [totalQty, setTotalQty] = useState(0);
  useEffect(() => {
    if (wines.length > 0) {
      const newWines = wines.map(wine => wine);
      // console.log('newWines', newWines);
      let newSubTotal = 0;
      let newtotalQty = 0;
      for (const wine of newWines) {
        newtotalQty = newtotalQty + wine.quantity;
        newSubTotal += wine.attributes.price.amount * wine.quantity;
      }
      setTotalQty(newtotalQty);
      setSubtotal(formatMoney(intl, new Money(newSubTotal, config.currency)));
    }
  }, [wines]);

  const inboxLink = authenticatedOnClientSide ? (
    <>
      {/* <SearchIcon rootClassName={css.SearchIcon} /> */}

      <Menu>
        <MenuLabel className={css.inboxLink} isOpenClassName={css.cartMenuIsOpen}>
          <span>
            <BasketIcon style={{ fill: 'none' }} />
            {totalQty}
          </span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="MyCart">
            {Object.keys(cartWines).length === 0 ? (
              <div
                style={{
                  width: '200px',
                  height: '100px',
                  textAlign: 'center',
                }}
              >
                <BottleIcon style={{ fill: 'none' }} />
                <p>Your cart is empty.</p>
              </div>
            ) : (
              <div style={{ width: '340px', overflowY: 'scroll', height: '500px' }}>
                <div>
                  <p style={{ textAlign: 'center', height: '5%' }}>
                    Your cart: {wines.length} items from {Object.keys(cartWines).length} wineries
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '100%',
                    height: '95%',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="my-box">
                    {Object.keys(cartWines).map(k => (
                      <div className={css.itemsWrapper}>
                        <div style={{ minWidth: '100%', marginTop: 10 }}>
                          {cartWines[k].map(wine => {
                            const wineTitle =
                              wine?.attributes?.publicData?.vintage !== undefined
                                ? wine?.attributes?.publicData?.vintage +
                                  ' ' +
                                  wine?.attributes.title
                                : `NV ${wine?.attributes.title}`;
                            const firstImage =
                              wine.images && wine.images.length > 0 ? wine.images[0] : null;
                            const formattedPrice = formatMoney(intl, wine?.attributes.price);

                            return (
                              <div className={css.cartWrapper}>
                                <div
                                  className={css.cartContainer}
                                  style={{
                                    padding: '10px 20px',
                                    width: '100%',
                                  }}
                                >
                                  <div className={css.cardImgWrapper} style={{ width: '20%' }}>
                                    <ResponsiveImage
                                      rootClassName={css.rootForImage}
                                      alt={wineTitle}
                                      image={firstImage}
                                      variants={['scaled-small']}
                                      sizes="(max-width: 481px) "
                                    />
                                  </div>

                                  <div
                                    className={css.titleWrapper}
                                    style={{
                                      width: '80%',
                                    }}
                                  >
                                    <h3 className={css.winerName}>{k}</h3>
                                    <p>{wine.attributes.title}</p>
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <span className={css.winerName}>{formattedPrice}</span>
                                      <span className={css.winerName}>Qty:{wine.quantity}</span>
                                      <span className={css.winerName}>
                                        <DeleteIcon
                                          style={{ fill: 'none' }}
                                          onClick={() => {
                                            // removing from cart
                                            removeFromStore(wine.id.uuid);
                                            dispatch(loadData(undefined, {}));
                                          }}
                                          className={css.deleteIcon}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      minWidth: '100%',
                      backgroundColor: '#F2F1EF',
                      padding: '17px 15px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        minWidth: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <h3>Sub-total</h3>
                      <h3>{subtotal}</h3>
                    </div>
                    <Button
                      onClick={() => {
                        history.push('/cart');
                      }}
                      rootClassName={css.viewCartButton}
                    >
                      View Cart
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </MenuItem>
        </MenuContent>
      </Menu>

      <NamedLink
        className={css.inboxLink}
        name="InboxPage"
        params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      >
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.inbox" />
          {/* {notificationDot} */}
        </span>
      </NamedLink>
    </>
  ) : (
    <Menu>
      <MenuLabel className={css.inboxLink} isOpenClassName={css.cartMenuIsOpen}>
        <span>
          <BasketIcon style={{ fill: 'none' }} />
          {totalQty}
        </span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="MyCart">
          {Object.keys(cartWines).length === 0 ? (
            <div
              style={{
                width: '200px',
                height: '100px',
                textAlign: 'center',
              }}
            >
              <BottleIcon style={{ fill: 'none' }} />
              <p>Your cart is empty.</p>
            </div>
          ) : (
            <div style={{ width: '340px', overflowY: 'scroll', height: '500px' }}>
              <div>
                <p style={{ textAlign: 'center', height: '5%' }}>
                  Your cart: {wines.length} items from {Object.keys(cartWines).length} wineries
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '100%',
                  height: '95%',
                  justifyContent: 'space-between',
                }}
              >
                <div className="my-box">
                  {Object.keys(cartWines).map(k => (
                    <div className={css.itemsWrapper}>
                      <div style={{ minWidth: '100%', marginTop: 10 }}>
                        {cartWines[k].map(wine => {
                          const wineTitle =
                            wine?.attributes?.publicData?.vintage !== undefined
                              ? wine?.attributes?.publicData?.vintage + ' ' + wine?.attributes.title
                              : `NV ${wine?.attributes.title}`;
                          const firstImage =
                            wine.images && wine.images.length > 0 ? wine.images[0] : null;
                          const formattedPrice = formatMoney(intl, wine?.attributes.price);

                          return (
                            <div className={css.cartWrapper}>
                              <div
                                className={css.cartContainer}
                                style={{
                                  padding: '10px 20px',
                                  width: '100%',
                                }}
                              >
                                <div className={css.cardImgWrapper} style={{ width: '20%' }}>
                                  <ResponsiveImage
                                    rootClassName={css.rootForImage}
                                    alt={wineTitle}
                                    image={firstImage}
                                    variants={['scaled-small']}
                                    style={{
                                      width: '100%',
                                    }}
                                    sizes="(max-width: 481px) "
                                  />
                                </div>

                                <div
                                  className={css.titleWrapper}
                                  style={{
                                    width: '80%',
                                  }}
                                >
                                  <h3 className={css.winerName}>{k}</h3>
                                  <p>{wine.attributes.title}</p>
                                  <div
                                    style={{
                                      display: 'flex',
                                      width: '100%',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <span className={css.winerName}>{formattedPrice}</span>
                                    <span className={css.winerName}>Qty:{wine.quantity}</span>
                                    <span className={css.winerName}>
                                      <DeleteIcon
                                        style={{ fill: 'none' }}
                                        onClick={() => {
                                          // removing from cart
                                          removeFromStore(wine.id.uuid);
                                          dispatch(loadData(undefined, {}));
                                        }}
                                        className={css.deleteIcon}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    minWidth: '100%',
                    backgroundColor: '#F2F1EF',
                    padding: '17px 15px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      minWidth: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <h3>Sub-total</h3>
                    <h3>{subtotal}</h3>
                  </div>
                  <Button
                    onClick={() => {
                      history.push('/cart');
                    }}
                    rootClassName={css.viewCartButton}
                  >
                    View Cart
                  </Button>
                </div>
              </div>
            </div>
          )}
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const adminLink = isAdmin ? (
    <NamedLink className={css.inboxLink} name="DhasboardPage" params={{ tab: 'wineries' }}>
      <span className={css.inbox}>Dashboard</span>
    </NamedLink>
  ) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="dashboard" className={css.tabletMenuItem}>
          {isAdmin ? (
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('DhasboardPage'))}
              name="DhasboardPage"
              params={{ tab: 'wineries' }}
            >
              <span className={css.menuItemBorder} />
              Dashboard
            </NamedLink>
          ) : null}
        </MenuItem>

        <MenuItem key="Inbox" className={css.tabletMenuItem}>
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.inbox" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="MyFavoritesPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('MyFavoritesPage'))}
            name="MyFavoritesPage"
          >
            <span className={css.menuItemBorder} />
            My Favorites
          </NamedLink>
        </MenuItem>

        <MenuItem key="MyReviewsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('MyReviewsPage'))}
            name="MyReviewsPage"
          >
            <span className={css.menuItemBorder} />
            My Reviews
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="WineryPageInfo">
          {isWineryOwner && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('WineryPageInfo'))}
              name="WineryPageInfo"
            >
              <span className={css.menuItemBorder} />
              My Winery
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          {isWineryOwner && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              My Wines
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
      <span className={css.signupDivider}> / </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <div className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>

      <nav className={css.navMenu}>
{/*         <NamedLink className={css.navMenuLink} name="ResearchWineries">
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.researchWineries" />
          </span>
        </NamedLink>
        <NamedLink className={css.navMenuLink} name="BuyWine">
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.buyWine" />
          </span>
        </NamedLink> */}
        {/* <NamedLink className={css.navMenuLink} name="WineClubs">
          Join Wine Clubs
        </NamedLink> */}
        <ExternalLink
          href="https://blog.sommly.com/"
          className={classNames(css.tabletMenu, css.navMenuLink)}
        >
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.blogPage" />
          </span>
        </ExternalLink>
        {/* <ExternalLink href="https://sommly.com/about/" className={css.navMenuLink}>
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.aboutPage" />
          </span>
        </ExternalLink> */}
       {/* <NamedLink className={classNames(css.tabletMenu, css.navMenuLink)} name="AboutPage">
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.aboutPage" />
          </span>
        </NamedLink>*/}
        {/* <NamedLink className={classNames(css.tabletMenu, css.navMenuLink)} name="WhysommlyPage">
          <span className={css.navMenuLinkText}>Why Sommly</span>
        </NamedLink> */}
        <NamedLink className={css.navMenuLink} name="LandingPage">
          <span className={css.navMenuLinkText}>Curated Wines</span>
        </NamedLink>
        {/*<NamedLink className={css.navMenuLink} name="WineryPlansPage">
          <span className={css.navMenuLinkText}>Winery Plans</span>
        </NamedLink>*/}
      </nav>

      <div className={css.navUsers}>
{/*         {adminLink}
        {inboxLink}
        {profileMenu}
        {signupLink}
        {loginLink} */}
      </div>
    </div>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
  } = state.CartPage;
  const wines = getListingsById(state, currentPageResultIds);

  return {
    currentUser,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    wines,
  };
};

export default compose(withRouter, connect(mapStateToProps), injectIntl)(TopbarDesktop);
