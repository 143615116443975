import React from 'react'
import Modal from '../Modal';
import {IoWarningOutline} from 'react-icons/io5';
import css from './DeleteModal.module.css';
import { PrimaryButton, SecondaryButton } from '../..';

const DeleteModal = ({
  open,
  setOpen,
  title,
  description,
  inProgress,
  handleDelete,
  onManageDisableScrolling = f => f
}) => {
  return (
    <Modal
      id="AuthenticationPage.tos"
      isOpen={open}
      onClose={() => setOpen(false)}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.modelWrapper}>
        <IoWarningOutline style={{color: '#ffcc00', fontSize: "24"}} />
        <h2 className={css.modelHeading}>{title}</h2>
        <p className={css.modelContent}>{description}</p>
        <div className={css.btnsWrapper}>
          <PrimaryButton
            style={{padding: "0 40px"}}
            type="button"
            inProgress={inProgress}
            disabled={inProgress}
            onClick={() => handleDelete()}
          >
            Yes
          </PrimaryButton>
          <SecondaryButton
            style={{marginLeft: "10px", padding: "0 30px"}}
            type="button"
            onClick={() => setOpen(false)}
            disabled={inProgress}
          >
            Cancel
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal;
