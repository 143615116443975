import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>Last Update: December 8, 2021</p>

      <h4>Introduction</h4>
      <p>
        This privacy policy (“Privacy Policy”) describes the collection of personal information by Sommly, LLC., a limited liability company (“Company,” “we,” “us”, or “our”) from users (“you”, “your”, “customer”) of our website at www.sommly.com (our “Site”) along with our related websites, networks, applications, platforms, and other services provided by us and on which a link to this Privacy Policy is displayed (collectively, our “Service” or “Services”). This Privacy Policy also describes our use and disclosure of such information. This Privacy Policy is incorporated by reference into our Terms and Conditions at https://sommly.com/termsandconditions/.
      </p>
      <p>
        Sommly’s Terms of Use require all account owners to be at least 21 years of age. You are responsible for any and all account activity conducted by a minor on your account. By using the Services, you acknowledge that Sommly will use your information in the United States and any other country where Sommly operates. Please be aware that the privacy laws and standards in certain countries, including the rights of authorities to access your personal information, may differ from those that apply in the country in which you reside. We will transfer personal information only to those countries to which we are permitted by law to transfer personal information.
      </p>
      <p>
        This Privacy Policy also describes your rights as a data subject to inquire about your personal information that we process and describes certain rights that you, as the data subject, have regarding this information.
      </p>
      <p>
        For the purposes of EU and United Kingdom data protection laws (“Data Protection Legislation“), Sommly is a data controller (i.e., the company that is responsible for, and controls the processing of, your personal information).
      </p>
      <p>
        Please read this Privacy Policy carefully to understand our practices regarding your personal information and how we will use it. By accepting this Privacy Policy and Terms and Conditions, you agree to our collection, storage, use and disclosure of your personal information as described in this Privacy Policy.
      </p>

      <h3>Contacting us</h3>
      <p>
        If you have any questions or comments about this Privacy Policy, please contact us using the following contact information:
      </p>
      <p>Sommly </p>
      <p>3001 E 18th ½ Street, Austin, Texas 78702</p>
      <p>privacy@sommly.com</p>

      <h2>What information do we collect?</h2>
      <p>
        We use your personal information to carry out the obligations arising from providing and improving the Service. This section describes the types and categories of personal information we may collect, and how we may use that information.
      </p>

      <h2>
        Information you provide us directly
      </h2>
      <p>
        We collect personal information that you provide when you sign up, enter your information into online forms, or otherwise use the Site and the Services. We use this personal information in a variety of ways, and this personal information includes the following:
      </p>
      <ul>
        <li>
          When you create an account on the Service, sign up for our mailing lists, provide feedback, or otherwise communicate with us, we may collect contact and business information from you, such as your first and last name, e-mail address, mailing address, one or more phone numbers and the company name with which you are associated with;
        </li>
        <li>
          When you order or use the Services, we will collect the information necessary to complete the transaction, including your first name, last name, credit card and/or bank account information, and billing address;
        </li>
        <li>
          When you submit a form, we may collect additional profile information, for example, information about your business, other products, and services you use, etc.;
        </li>
        <li>
          We also collect other types of personal information that you provide to us voluntarily, such as if you contact us via the Site regarding customer support or other questions regarding the Services.
        </li>
      </ul>

      <h2>
        Automatically collected information
      </h2>
      <p>
        If you decide to invite a third party to create an account with the Service, we will collect the third party’s first name, last name and email addresses in order to send an invitation email to, and follow up with, the third party. You should ensure that you know the third-party and they are expecting this invitation. In any case, you and/or the third party may contact us at privacy@sommly.com to request the removal of this information from our databases.
      </p>
      <p>
        We may receive information about you, including personal information, from third parties, to increase the accuracy of the personal information you have provided us directly. Additionally, if you provide your personal information by entering it on our Site, we may use publicly available information from other websites, for example, your social networking profile photo, to augment your personal information to provide a more personalized service. We treat this combined information as personal information under this Privacy Policy.
      </p>

      <h2>
        Cookies and Similar Technologies
      </h2>
      <p>
        The Service uses cookies and similar technologies to distinguish you from other users of the Service. This enables us to provide you with the service specifically linked to your user profile and is required for the Service to function. Cookies are small files that allow for personalization of the Service experience by saving your information such as user ID and other preferences. The service also uses a related technology called local storage which allows preferences and session information to be stored locally on your computer or mobile device.
      </p>
      <p>
        Also, please be aware that third parties, such as companies displaying advertisements on the Service or the sites or services provided by third parties (“Third-Party Sites”) that may be linked to from the Service, may set cookies or use other means of passively collecting information about your use of their services, Third-Party Sites or content. We do not have access to, or control over, these third-party means of passive data collection. For more information, please refer to our cookie policy at https://sommly.com/cookie-policy/.
      </p>

      <h2>
        Collection and processing of sensitive information
      </h2>
      <p>
        The Service does not collect or process ‘sensitive information’, defined as data consisting of racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, genetic data, biometric data, data concerning health or data concerning a natural person’s sex life or sexual orientation. As such, you should not provide any such information as part of your user profile, as part of any correspondence, or by any other means with your use of the Service.
      </p>

      <div>
        <h2>
          How do we use personal information?
        </h2>
        <p>
          We collect personal information when you sign up for the Services or when you update your account information. We use your personal information for the following purposes:
        </p>

        <ul>
          <li>
            To facilitate the creation of your account with the Service, to secure and personalize your interaction with the Service, and to provide the Services you have requested;
          </li>
          <li>
            To send you a welcome email to verify ownership of the email address provided when your account was created with the Service
          </li>
          <li>
            To send you administrative email notifications, such as support and maintenance advisories;
          </li>
          <li>
            We may also use the personal information you provide to contact you regarding your use of the Service or to solicit feedback;
          </li>
          <li>
            When you communicate with us using one of the methods described in this Privacy Policy, we may also keep a record of the time and date of any correspondence, and also organize this correspondence in one or more of an electronic filing system, an email system or a customer relationship management system;
          </li>
          <li>
            We link this personal information to data about the way you use our Service and the pages you visit to help enhance, improve, operate, and maintain our Service, our platforms, websites, and other systems;
          </li>
          <li>
            To prevent fraudulent use of our Service and other systems;
          </li>
          <li>
            To prevent or take action against activities that are, or may be, in violation of our Terms and Conditions or applicable law;
          </li>
          <li>
            We may also use the personal information you provide for direct marketing of our Services to you. We allow you to opt-out from receiving marketing communications from us as described in the “Communication choices” section below, and also at the time you sign up and create an account with the Service. Even if you opt-out, we may continue to send you administrative emails, including, for example, periodic updates to this Privacy Policy;
          </li>
          <li>
            To display personalized or targeted content to you, and to display targeted advertising on third party websites;
          </li>
          <li>
            For internal product development purposes to develop new products and services, and to improve existing ones;
          </li>
          <li>
            To respond to your inquiries related to employment opportunities with us, or other general inquiries.
          </li>
        </ul>
      </div>

      <div>
        <h2>
          Legal basis for processing in the EU and the United Kingdom
        </h2>
        <p>
          If you are resident in the EU or the United Kingdom, we need to inform you about the legal basis on which we collect and use your personal information. In the EU and the United Kingdom, the purposes for which we process your personal information are:
        </p>
        <ul>
          <li>
            Where we need to perform the contract we are about to enter into or have entered into with you for the Service;
          </li>
          <li>
            For the purposes of legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests;
          </li>
          <li>
            Where you have given consent to the processing for one or more specific purposes; and
          </li>
          <li>
            Where we need to comply with a legal or regulatory obligation in the EU and the United Kingdom.
          </li>
        </ul>
        <p>
          The legal basis depends on the category of personal data being processed, and the purpose for that processing. The following table indicates each category of personal data we process, and the legal bases we rely on to do so. Where legitimate interest has been used as the legal basis for processing, the specific legitimate interest we use has been described. Please contact us if you need details about the specific legal basis we are relying on to process your personal data where one or more legal bases has been indicated.
        </p>


      </div>

      <div>
        <h2>
          When do we share personal information?
        </h2>
        <p>
          Except as described in this Privacy Policy, we will not disclose your personal information that we collect on the Service to third parties without your consent. We may disclose information to third parties if you consent to us doing so, as well as in the following circumstances:
        </p>

        <ul>
          <li>
            Service Providers. We may disclose personal information to third-party service providers (e.g., web hosting providers and other SaaS providers) that assist us in our work. We limit the personal information provided to these service providers to that which is reasonably necessary for them to perform their functions, and we require them to agree to maintain the confidentiality of such personal information.
          </li>
          <li>
            Co-marketing Partners. We may disclose personal information to various co-marketing partners we engage with from time to time. An example would be when we co-host a webinar or similar online event and either we or our partners collect your personal information. The information you provide may be used to send you information about products and services related to the webinar you are attending, or for any other related purpose you would reasonably expect to take place
          </li>
          <li>
            Business Transfers. Information about our users, including personal information, may be disclosed and otherwise transferred to an acquirer, or successor or assignee as part of any merger, acquisition, debt financing, sale of company assets, or similar transaction, as well as in the event of an insolvency, bankruptcy, or receivership in which personal information is transferred to one or more third parties as one of our business assets.
          </li>
          <li>
            To Protect our Interests. We also disclose personal information if we believe that doing so is legally required, or is in our interest to protect our property or other legal rights (including, but not limited to, enforcement of our agreements), or the rights or property of others, or otherwise to help protect the safety or security of our Service and other users of the Service.
          </li>
          <li>
            To Comply with the Law: We may also disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
          </li>
        </ul>
      </div>
      <div>
        <h2>
          Communication choices
        </h2>
        <p>
          If you receive a marketing email from us, you may unsubscribe at any time by following the instructions contained within the email. You may also opt-out from receiving marketing email from us, and any other promotional communications that we may send to you from time to time (e.g., by postal mail) by sending your request to us by email at privacy@sommly.com or by writing to us at the address given in the ‘Contacting Us’ section of this Privacy Policy.
        </p>
        <p>
          Please be aware that if you opt-out of receiving marketing email from us, it may take up to ten business days for us to process your opt-out request, and you may receive marketing email from us during that period. Additionally, even after you opt-out from receiving marketing messages from us, you will continue to receive administrative and transactional messages from us regarding your use of the Service.
        </p>
      </div>

      <div>
        <h2>
          Rights to access
        </h2>
        <p>
          If you have a user account and profile on our Service, you have the ability to access and update many categories of personal information that you provide to us by logging in to your account and accessing your account settings. If you wish to access or amend any other personal information we hold about you, you may contact us at  privacy@sommly.com. If you request that we delete your account with our Service, we will do so within a reasonable period of time, but we may need to retain some of your personal information in order to satisfy our legal obligations, or where we reasonably believe that we have a legitimate reason to do so.
        </p>
      </div>

      <div>
        <h2>
          Links to external sites
        </h2>
        <p>
          The Service may contain links to other websites, products, or services that we do not own or operate. The Service also may contain links to Third-Party Sites such as social networking services. If you choose to visit or use any Third-Party Sites or products or services available on or through such Third-Party Sites, please be aware that this Policy will not apply to your activities or any information you disclose while using those Third-Party Sites or any products or services available on or through such Third-Party Sites. We are not responsible for the privacy practices of these Third-Party Sites or any products or services on or through them. Additionally, please be aware that the Service may contain links to websites and services that we operate but that are governed by different privacy policies. We encourage you to carefully review the privacy policies applicable to any website or service you visit other than the Service before providing any personal information on them.
        </p>
      </div>

      <div>
        <h2>
          How long do we keep your personal information for?
        </h2>
        <p>
          Unless otherwise specifically stated elsewhere in this Privacy Policy, we will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
        </p>
        <p>
          Aggregated and anonymized data that no longer identifies the user of the Service is maintained for the purposes necessary to provide the Service.
        </p>
      </div>

      <div>
        <h2>
          EU privacy rights
        </h2>
        <p>
          If you are located in the EU, you have the following Data Subject Access Rights with respect to your personal information that we hold:
        </p>
        <ul>
          <li>
            Right of access. You have the right to access the personal information that we hold about you;
          </li>
          <li>
            Right to rectification. You may have the right to require us to correct any inaccurate or incomplete personal information we hold about you;
          </li>
          <li>
            Right to erasure. In certain circumstances, you may have the right to the erasure of your personal data we hold about you (for example where it is no longer necessary in relation to the purposes for which it was collected or processed)
          </li>
          <li>
            Right to restriction. You may have the right to request that we restrict processing of your personal information in certain circumstances (for example where the accuracy of the personal data is contested by you, for a period enabling us to verify the accuracy of that personal data);
          </li>
          <li>
            Right to portability. In some limited circumstances, you may have the right to portability which allows you to move, copy or transfer personal data from one organization to another
          </li>
          <li>
            Right to object. You have a right to object to us processing your personal information when the processing is based on legitimate interests and also to stop us from sending you direct marketing;
          </li>
          <li>
            Rights in relation to automated decision making and profiling. You have the right not to be subject to a decision that affects you based solely on automated processing. We do not perform any automated decision making and profiling.
          </li>
          <li></li>
        </ul>
        <p>
          If you wish to exercise one of these rights, please contact us using the information in the ‘Contacting us’ section of this Privacy Policy.
        </p>
        <p>
          Sommly, LLC. respects the personal information (such as title, name, telephone number, email address etc) of people from whom it collects information and complies with the General Data Protection Regulation (EU 679/2016) (GDPR)/UK (GDPR).
        </p>
      </div>

      <div>
        <h2>
          Where do we store and process your personal information?
        </h2>
        <p>
          When Sommly obtains personal information about you, we may process and store the information outside of the country in which you are located, including in the United States. The countries in which we process the information may not have the same data protection laws as the country in which you are located. If you are using the Site or Services from the European Union (“EU”), the United Kingdom, Asia, or any other region with laws or regulations governing personal data collection, use, and disclosure, that differ from United States laws, please note that you are transferring your personal data to the United States which does not have the same data protection laws as the EU, the United Kingdom, and other regions, and by providing your personal data you permit the use of your personal data for the uses identified above in accordance with the Privacy Policy. For the purposes of Data Protection Legislation, countries that are deemed to not provide adequate protection are referred to as third countries (“Third Countries”) in this Privacy Policy. You consent to have your personal data transferred to and processed in the United States and other countries.
        </p>
      </div>

      <div>
        <h2>
          California privacy disclosures
        </h2>
        <p>
          California law requires us to let you know how we respond to web browser Do Not Track (DNT) signals. Because there currently isn’t an industry or legal standard for recognizing or honoring DNT signals, we haven’t responded to them at this time. We await the result of work by the privacy community and industry to determine when such a response is appropriate and what form it should take.
        </p>
        <p>
          A California resident who has provided personal information to a business with whom he/she has established a business relationship for personal, family, or household purposes (“California Customer”) is entitled to request information about whether the business has disclosed personal information to any third parties for the third parties’ direct marketing purposes, subject to certain exceptions, as defined in California Civil Code Sec. 1798.83. In general, subject to certain exceptions, if the business has made such a disclosure of personal information, upon receipt of a request by a California Customer, the business is required to provide, free of charge, a list of all third parties to whom personal information was disclosed in the preceding calendar year, as well as a list of the categories of personal information that were disclosed. California Customers may request further information about our compliance with this law by sending an email to privacy@sommly.com.
        </p>
      </div>

      <div>
        <h2>
          How do we secure your personal information?
        </h2>
        <p>
          To help protect your data, we use commercially reasonable steps to protect the data that we collect, including your personal information. The reasonable steps include protecting this data against accidental loss, unauthorized use, disclosure, and restricting access to personal information by our staff. The Service is hosted by a third-party hosting company that we have determined maintains adequate security controls and utilizes TLS encryption for all internet communication with the Service. We also require all staff that administer and develop the service follow industry-standard controls, including strong passwords, the use of anti-virus and anti-malware software, disk encryption and other best practices.
        </p>
        <p>
          We use various 3rd party processors to enable us to provide the Service, and as part of our vendor due-diligence, we review the security controls these processors have in place and ensure they meet industry standards appropriate for the type of data we collect.
        </p>
        <p>
          You should keep in mind, however, that the Service utilizes software, hardware, and networks, which from time to time require maintenance and experience problems beyond our control. Note that no data transmission over the public internet or encryption method can be guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security of any information that you provide to us. You transmit information to us at your own risk.
        </p>
      </div>

      <div>
        <h2>
          Updates to this Policy
        </h2>
        <p>
          We may occasionally update this Policy. When we do, we will also revise the “last updated” date at the beginning of the Policy. Your continued use of our Service after such changes will be subject to the then-current policy. If we change this Policy in a manner that is material, we will use reasonable efforts to notify you via the contact methods you have provided of the change prior to applying the change to any personal information that we collected from you prior to the date the change becomes effective. We encourage you to periodically review this Privacy Policy to stay informed about how we collect, use, and disclose personal information.
        </p>
      </div>



    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
