import React from 'react';
import css from './Upload.module.css';
import classNames from 'classnames';
import { IconSpinner } from '..';
import {IoIosAdd} from 'react-icons/io'


const UploadImageAndVideo = ({title, handleOnChange, uploadLoading, className}) => {
  const rootClasses = classNames(css.root, className ? className : "");
  return (
    <div>
      <label className={rootClasses}>
        {uploadLoading ? (
          <>
            Uploading...
            <IconSpinner />
          </>
        ) : (
          <>
            <IoIosAdd />
            {title}
            <input
              type="file"
              // id={`upload-${id}`}
              className={css.uploadInput}
              accept="image/*, video/*"
              onChange={handleOnChange}
              hidden
            />
          </>
        )}
      </label>
    </div>
  )
}

export default UploadImageAndVideo;
