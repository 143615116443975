import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';
import { Button, PrimaryButton } from '..';

const TermsOfService = props => {
  const {
    rootClassName,
    className,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>Sommly Terms of Service</p>
      <p className={css.lastUpdated}>Last Updated: 02/22/2022</p>
      <p>
        Sommly, LLC (“Sommly” or “we”) operates a website-based marketplace enabling individual users (“Users”) to connect with wineries (“Vendors”) who are offering wine and other products (“Vendor Products”) for purchase. These Terms of Service (“Terms”) apply to your access to and use of the Sommly website, mobile application, and any other online products or services provided by Sommly (collectively, the “Services”). You may be using the Services in the capacity of a User or a Vendor, and the terms applicable to Users or Vendors will apply to you in your capacity as a User or Vendor
      </p>
      <p>
        The services are for persons 21 years of age or older. You may not use the services if you are under 21.
      </p>
      <p>
        By accessing or using our services, you agree to be bound bou these terms and all terms incorporated by reference. If you do not agree to these terms in their entirety, do not use Sommly’s services.
      </p>
      <p>
        We may make changes to these Terms from time to time. If we make changes, we will provide you with notice of such changes, such as by sending an email, providing a notice through our Services or updating the date at the top of these Terms. Unless we say otherwise in our notice, the amended Terms will be effective immediately, and your continued use of our Services after we provide such notice will confirm your acceptance of the changes. If you do not agree to the amended Terms, you must stop using our Services.
      </p>
      <p>
        If you have any questions about these Terms or our Services, please contact us at info@sommly.com or by phone at (512) 553-3745.
      </p>

      <div>
        <h2>
          The Services
        </h2>
        <p>
          The Services are a neutral marketplace that enables communication and transactions between Users seeking to purchase, and Vendors offering, Vendor Products. As a provider of a neutral marketplace, Sommly does not own, create, offer to sell, sell, resell, provide, control, manage, offer, deliver, or supply any Vendor Products. Vendors alone are responsible for their Vendor Products, and all Vendor Products are subject to availability. When a User purchases Vendor Products made available via the Services, he or she is entering into a sales contract directly with the Vendor, not Sommly, and Sommly is not and does not become a party to, or other participant in, any contractual relationship between Users and Vendors. Sommly is not acting as an agent in any capacity for any User or Vendor. Without limiting the foregoing, Sommly assumes no liability or responsibility for the actions of any user or any vendor, including without limitation, any damages associated with any vendor products or resulting from any transactions between user and vendors.
        </p>
        <p>
          We have no control over and do not guarantee (a) the existence, availability, quality, safety, suitability, or legality of any Products, (b) the truth or accuracy of any Product descriptions, ratings, reviews, or Content (as defined below), or (c) the performance or conduct of any Vendor, User, or third party. Sommly does not endorse any Vendor, User, or Products. Any description of any User or Vendor is not an endorsement, certification or guarantee by Sommly about any such User or Vendor, including, without limitation, of the User’s or Vendor’s identity or background or whether the User or Vendor is trustworthy, safe or suitable. You should always exercise due diligence and care when deciding whether to offer to make a purchase for Vendor Products, accept an Order (as defined below) from a User, or communicate and interact with other Users or Vendors, whether online or in person. Any Content posted by a Vendor or about Vendor Products is not an endorsement by Sommly of any Vendor or Vendor Products.
        </p>
        <p>
          You are solely responsible for your interactions with Users or Vendors, as applicable, and Sommly is not a party to any disputes that may arise between you and the User or Vendor, nor does Sommly have any obligation to mediate or facilitate any resolution.
        </p>
      </div>

      <div>
        <h2>
          Privacy
        </h2>
        <p>
          For information about how we collect, use, share or otherwise process information about you, please see our Privacy Policy at https://sommly.com/privacy-policy/
        </p>
      </div>

      <div>
        <h2>
          Eligibility
        </h2>
        <p>
          You must be at least 21 years of age to use our Services. If you use our Services on behalf of another person or entity, (a) all references to “you” throughout these Terms will include that person or entity, (b) you represent that you are authorized to accept these Terms on that person’s or entity’s behalf, and (c) in the event you or the person or entity violates these Terms, the person or entity agrees to be responsible to us.
        </p>
      </div>

      <div>
        <h2>
          Accounts and Account Security
        </h2>
        <p>
          You may need to register for an account to access some or all of our Services. If you register for an account, you must provide accurate account information and promptly update this information if it changes. You also must maintain the security of your account and promptly notify us if you discover or suspect that someone has accessed your account without your permission. You are responsible for all activities that occur in connection with your account. We reserve the right to reclaim usernames, including on behalf of businesses or individuals that hold legal claim, including trademark rights, in those usernames. If we terminate or suspend your account you may not create additional accounts.
        </p>
      </div>

      <div>
        <h2>
          User-Specific Terms
Placing an Order
        </h2>
        <p>
          Subject to meeting any requirements set by Sommly, you can place an order for Products (an “Order”) by following the process set forth on the Services. When you place an Order, it is an offer to purchase the Vendor Products from the applicable Vendor and is subject in all respects to the Vendor’s approval, which the Vendor may grant or withhold in his, her, or its sole discretion.
        </p>
        <p>
          All applicable prices for Vendor Products, including delivery fees, will be presented to you prior to placing the Order. You agree to pay all fees charged in the Order.
        </p>
      </div>

      <div>
        <h2>
          Payment Method
        </h2>
        <p>
          Sommly or the Vendor will collect fees from you after you place an Order. When placing an Order, you will be required to provide a valid, up-to-date credit card, debit card, bank account, or other payment method or information (“Payment Method”). We will either charge your Payment Method directly or will enable the Vendor to charge your Payment Method. You authorize us to charge, or enable the Vendor to charge, your Payment Method for fees you incur on the Services as they become due and payable. You are responsible for maintaining an up-to-date Payment Method. If we or the Vendor cannot charge you for any fees when due because your Payment Method is no longer valid, or if we or the Vendor do not receive your payment when due, then you understand that neither Sommly nor Vendor will be responsible for any failure to deliver the Vendor Products associated with those fees.
        </p>
      </div>

      <div>
        <h2>
          Order Approval and Processing
        </h2>
        <p>
          If the Vendor approves your Order, the Vendor will arrange for processing the Order (including, in some instances, accepting payment from you), communicating with you, and shipping the Vendor Products to you. Upon Vendor’s approval of your Order, a legally binding agreement is formed between you and the Vendor, subject to any additional terms and conditions of the Vendor that may apply, including any rules or restrictions specified by the Vendor, except to the extent those terms or conditions conflict with these Terms, in which case these Terms will control.
        </p>
        <p>
          Except for taxes on Sommly’s income and gross receipts or where Sommly is otherwise required to collect taxes, you are solely responsible for paying any applicable taxes that arise as a result of your use of the Services.
        </p>
      </div>

      <div>
        <h2>
          No Refunds or Cancellations
        </h2>
        <p>
          All fees are non-cancelable and non-refundable once confirmed. The Vendor may cancel any Order in its sole discretion. Returns and refunds are at the sole discretion of the Vendor and must be arranged directly between you and the Vendor. Sommly has no involvement in, and will not be responsible for the facilitation of any, returns or refunds, whether as a result of Vendor cancellation or otherwise. If you have a bad experience at Sommly, please email us at info@sommly.com and we will attempt to address your concern accordingly.
        </p>
      </div>

      <div>
        <h2>Your Representations, Warranties and Covenants</h2>
        <p>
          By using the Services, you represent, warrant and covenant that you are acting, and will continue to act at all times during your use of the Services, in compliance with all applicable federal, state, and local laws, rules and regulations, including, without limitation, those regarding the purchase, transportation and delivery of alcoholic beverages. You further represent that (a) are legally entitled to purchase and take possession of alcoholic beverages, (b) are legally entitled to take quantities for which you placed any Orders and that you and the person accepting delivery of any alcoholic beverages are at least 21 years of age, and (c) you will only use the Services for personal, non-commercial use and you will not resell, distribute, or otherwise commercially exploit any products or services (including, without limitation, Vendor Products) that are made available via the Services.
        </p>
      </div>

      <div>
        <h2>
          Refusing, Limiting, or Canceling Orders
        </h2>
        <p>
          We reserve the right to refuse, limit, or cancel any Order at any time in our sole discretion, including, without limitation, refusing, limiting, or canceling quantities purchased per User, per household, or per Order. These restrictions may include Orders placed by or under the same User account, Order placed using the same Payment Method, and/or Orders that use the same billing or shipping address. We reserve the right to limit or prohibit Orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.
        </p>
      </div>
      <div>
        <h2>
          Content
        </h2>
        <p>
          Our Services allow you to create, post, store and share content, including messages, reviews, photos, scores, video, graphics, tags, links, and other materials (collectively, “Content”). Except for the license you grant below, you retain all rights in and to your Content, as between you and Sommly.
        </p>
      </div>
      <div>
        <h2>
          Vendor-Specific Terms
Listing your Vendor Products
        </h2>
        <p>
          When creating a listing of your Products on the Services, you must (a) provide complete and accurate information about your Products, (b) disclose any deficiencies, restrictions, and requirements that apply, and (c) provide any other pertinent information required by Sommly. You are responsible for keeping information about your Vendor Services up-to-date at all times. When you accept an Order from a User, you enter into a legally binding agreement with that User and are required to provide your Vendor Products to that User as described to the User (including, without limitation, with respect to the quality, timeliness, and other characteristics of the deliverables promised to the User and any promotions, discounts, and other terms offered to the User).
        </p>
      </div>

      <div>
        <h2>
          Prices and Collection of Fees
        </h2>
        <p>
          You are solely responsible for setting a price for your Vendor Products. Any terms and conditions included in your Order acceptance or otherwise made available by you to the User must not conflict with these Terms. Any Content you post to the Services must be accurate and complete, including, without limitation, accurately reflecting your Vendor Products. Except for taxes on Sommly’s income and gross receipts or where Sommly is otherwise required to collect taxes, you are solely responsible for paying any applicable taxes as a result of your use of the Services.
        </p>
      </div>
      <div>
        <h2>
          Vendor Accounts
        </h2>
        <p>
          You authorize us to charge your Account for our service fees that you incur on the Services as they become due and payable. You are responsible for maintaining an up-to-date Account. If we cannot charge you for any service fees when due because your Account is no longer valid, or if we do not receive your payment when due, then you understand that we may immediately suspend your use of the Services until you update your Vendor Account.
        </p>
        <p>
          Payment processing services for Vendors are provided by Stripe and are subject to the Stripe Connect Account Agreement, which includes the Stripe Terms of Service (collectively, the “Stripe Services Agreement. By agreeing to these Terms or continuing to operate as a Vendor, you agree to be bound by the Stripe Services Agreement, as the same may be amended from time to time.
        </p>
      </div>
      <div>
        <h2>
          Your Representations, Warranties and Covenants
        </h2>
        <p>
          By using the Services in your capacity as a Vendor, you represent, warrant and covenant that you are in compliance with, will continue to comply at all times during your use of the Services with, and will fulfill all Orders and sell all Vendor Products in compliance with, all applicable federal, state, and local laws, rules, and regulations, including, without limitation, those regarding the purchase, transportation and delivery of alcoholic beverages, including, without limitation, that you and any intermediaries through which you sell, ship, or otherwise distribute the Vendor Products maintain in full force and effect all licenses, registrations, and permits to sell, ship, deliver, and otherwise distribute alcoholic beverages directly to individual consumers.
        </p>
      </div>

      <div>
        <h2>
          Your Relationship with Sommly
        </h2>
        <p>
          If you choose to use the Services as a Vendor, your relationship with Sommly is limited to being an independent, third-party marketplace participant, and not an employee, agent, joint venturer or partner of Sommly for any reason, and you act exclusively on your own behalf and for your own benefit, and not on behalf, or for the benefit, of Sommly. Sommly does not, and will not be deemed to, direct or control you generally or in your performance under these Terms specifically, including, without limitation, in connection with your provision of the Vendor Products. You acknowledge and agree that you have complete discretion whether to list Vendor Products or otherwise engage in other business activities.
        </p>
      </div>

      <div>
        <h2>
          Content
        </h2>
        <p>
          Our Services allow you to create, post, store and share content, including messages, text, photos, videos, audio, graphics, tags, links, software and other materials (collectively, “Content”). Except for the license you grant below, you retain all rights in and to your Content, as between you and Sommly.
        </p>
        <p>
          You grant Sommly a perpetual, irrevocable, nonexclusive, royalty-free, worldwide, fully paid, and sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, publicly perform and display your Content and any name, username or likeness provided in connection with your Content in all media formats and channels now known or later developed without compensation to you. When you post or otherwise share Content on or through our Services, you understand that your Content and any associated information (such as your username or profile photo) may be visible to others. If your name, voice, image, persona, likeness, or performance is included in any of Content, you hereby waive and release Sommly and its Users and Vendors from any claim or cause of action, whether known or unknown, for defamation, copyright infringement, invasion of the rights of privacy, publicity, or personality, or any similar claim arising out of the use of Content in accordance with the license in these Terms.
        </p>
        <p>
          You may not create, post, store or share any Content that violates these Terms or for which you do not have all the rights necessary to grant us the license described above. You represent and warrant that your Content, and our use of such Content as permitted by these Terms, does not and will not violate any rights of or cause injury to any person or entity. Although we have no obligation to screen, edit or monitor Content, we may delete or remove Content at any time and for any reason with or without notice.
        </p>
      </div>
      <div>
        <h2>
          Prohibited Conduct and Content
        </h2>
        <p>
          You will not violate any applicable law, contract, intellectual property right or other third-party right or commit a tort, and you are solely responsible for your conduct while using our Services. You will not:
        </p>
        <ul>
          <li>
            Engage in any harassing, threatening, intimidating, predatory or stalking conduct;
          </li>
          <li>
            Use or attempt to use another User or Vendor’s account without authorization from that User or Vendor and Sommly;
          </li>
          <li>
            Engage in tactics to bypass the Services or other Sommly systems to avoid complying with any of our applicable policies, including these Terms, paying any applicable fees, or complying with other contractual obligations;
          </li>
          <li>
            Impersonate or post on behalf or any person or entity or otherwise misrepresent your affiliation with a person or entity;
          </li>
          <li>
            Sell, resell or commercially use our Services;
          </li>
          <li>
            Copy, reproduce, distribute, publicly perform or publicly display all or portions of our Services, except as expressly permitted by us or our licensors;
          </li>
          <li>
            Modify our Services, remove any proprietary rights notices or markings, or otherwise make any derivative works based upon our Services;
          </li>
          <li>
            Use our Services other than for their intended purpose and in any manner that could interfere with, disrupt, negatively affect or inhibit other Users or Vendors from fully enjoying our Services or that could damage, disable, overburden or impair the functioning of our Services in any manner;
          </li>
          <li>
            Attempt to circumvent any content-filtering techniques we employ or attempt to access any feature or area of our Services that you are not authorized to access;
          </li>
          <li>
Use any data mining, robots or similar data gathering or extraction methods designed to scrape or extract data from our Services;
          </li>
          <li>
            Develop or use any applications that interact with our Services without our prior written consent;
          </li>
          <li>
            Send, distribute or post spam, unsolicited or bulk commercial electronic communications, chain letters, or pyramid schemes;
          </li>
          <li>
            Use our Services for any illegal or unauthorized purpose, or engage in, encourage or promote any activity that violates these Terms.
          </li>
        </ul>
        <p>
          You may also post or otherwise share only Content that is nonconfidential and that you have all necessary rights to disclose. You may not create, post, store or share any Content that:
        </p>
        <ul>
          <li>
            Is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory or fraudulent;
          </li>
          <li>
            Would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party or otherwise create liability or violate any local, state, national or international law;
          </li>
          <li>
            May infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any party;
          </li>
          <li>
            Contains or depicts any statements, remarks or claims that do not reflect your honest views and experiences;
          </li>
          <li>
            Impersonates, or misrepresents your affiliation with, any person or entity;
          </li>
          <li>
            Contains any unsolicited promotions, political campaigning, advertising or solicitations;
          </li>
          <li>
            Contains any private or personal information of a third party without such third party’s consent;
          </li>
          <li>
            Contains any viruses, corrupted data or other harmful, disruptive or destructive files or content; or
          </li>
          <li>
            In our sole judgment, is objectionable, restricts or inhibits any other person from using or enjoying our Services, or may expose Sommly or others to any harm or liability of any type.
          </li>
        </ul>
        <p>
          Enforcement of this section is solely at Sommly’s discretion, and failure to enforce this section in some instances does not constitute a waiver of our right to enforce it in other instances.
        </p>
      </div>

      <div>
        <h2>
          Ownership; Limited License
        </h2>
        <p>
          The Services, including the text, graphics, images, photographs, videos, illustrations and other content contained therein, are owned by Sommly or our licensors and are protected under both United States and foreign laws. Except as explicitly stated in these Terms, all rights in and to the Services are reserved by us or our licensors. Subject to your compliance with these Terms, you are hereby granted a limited, nonexclusive, nontransferable, non-sublicensable, revocable license to access and use our Services for your own personal, noncommercial use. Any use of the Services other than as specifically authorized herein, without our prior written permission, is strictly prohibited, will terminate the license granted herein and violate our intellectual property rights.
        </p>
      </div>

      <div>
        <h2>
          Trademarks
        </h2>
        <p>
          The Sommly name and our logos, our product or service names, our slogans and the look and feel of the Services are registered trademarks of Sommly and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and company names or logos mentioned on the Services are the property of their respective owners. Reference to any products, services, processes or other information by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation by us.
        </p>
      </div>

      <div>
        <h2>Feedback</h2>
        <p>
          You may voluntarily post, submit or otherwise communicate to us any questions, comments, suggestions, ideas, original or creative materials or other information about Sommly or our Services (collectively, “Feedback”). You understand that we may use such Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you, including, without limitation, to develop, copy, publish, or improve the Feedback in Sommly’s sole discretion. You understand that Sommly may treat Feedback as nonconfidential.
        </p>
      </div>

      <div>
        <h2>
        Third-Party Content
        </h2>
        <p>
          We provide information about, or links to, third-party products, services, activities or events, or we may allow third parties to make their content and information available on or through the Services (other than Vendor Products) (collectively, “Third-Party Content”). We provide Third-Party Content as a service to those interested in such content. Your dealings or correspondence with third parties and your use of or interaction with any Third-Party Content are solely between you and the third party. Sommly does not control or endorse, and makes no representations or warranties regarding, any Third-Party Content, and your access to and use of such Third-Party Content is at your own risk.
        </p>
      </div>

      <div>
        <h2>
          Indemnification
        </h2>
        <p>
          To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless Sommly and our officers, directors, agents, partners and employees (individually and collectively, the “Sommly Parties”) from and against any losses, liabilities, claims, demands, damages, expenses or costs (“Claims”) arising out of or related to (a) your access to or use of the Services; (b) your Content or Feedback; (c) your violation of these Terms; (d) your violation, misappropriation or infringement of any rights of another (including intellectual property rights or privacy rights); or (e) your conduct in connection with the Services. You agree to cooperate with the Sommly Parties in defending such Claims.
        </p>
      </div>

      <div>
        <h2>
          Disclaimers
        </h2>
        <p>
          Your use of our Services is at your sole risk. Except as otherwise provided in a writing by us, our Services and any content therein are provided “as is” and “as available” without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In addition, Sommly does not represent or warrant that our Services are accurate, complete, reliable, current or error-free. While Sommly attempts to make your use of our Services and any content therein safe, we cannot and do not represent or warrant that our Services or servers are free of viruses or other harmful components. You assume the entire risk as to the quality and performance of the Services.
        </p>
      </div>

      <div>
        <h2>
          Limitation of Liability
        </h2>
        <p>
          To the fullest extent permitted by applicable law, Sommly and the other Sommly Parties will not be liable to you under any theory of liability—whether based in contract, tort, negligence, strict liability, warranty, or otherwise—for any indirect, consequential, exemplary, incidental, punitive or special damages or lost profits, even if Sommly or the other Sommly Parties have been advised of the possibility of such damages.
        </p>
        <p>
          The total liability of Sommly and the other Sommly Parties for any claim arising out of or relating to these Terms or our Services, regardless of the form of the action, is limited to the greater of (a) $500 and (b) the amount paid by you to use our Services.
        </p>
        <p>
          The limitations set forth in this section will not limit or exclude liability for the gross negligence, fraud or intentional misconduct of Sommly for any other matters in which liability cannot be excluded or limited under applicable law. Additionally, some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you.
        </p>
      </div>

      <div>
        <h2>
          Release
        </h2>
        <p>
          To the fullest extent permitted by applicable law, you release Sommly and the other Sommly Parties from responsibility, liability, claims, demands and/or damages (actual and consequential) of every kind and nature, known and unknown (including, but not limited to, claims of negligence), arising out of or related to (a) disputes between you and any User(s) or you and any Vendor(s), (b) the acts or omissions of any User(s), Vendor(s) and/or third parties, and/or (c) the provision of the Vendor Services.
        </p>
        <p>
          If you are a consumer who resides in California, you hereby waive your rights under California Civil Code § 1542, which provides: “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”
        </p>
      </div>
      <div>
        <h2>
          Transfer and Processing Data
        </h2>
        <p>
          In order for us to provide our Services, you agree that we may process, transfer and store information about you in the United States and other countries, where you may not have the same rights and protections as you do under local law.
        </p>
      </div>
      <div>
        <h2>
          Governing Law and Venue
        </h2>
        <p>
          Any dispute arising from these Terms and your use of the Services will be governed by and construed and enforced in accordance with the laws of the State of Texas, except to the extent preempted by U.S. federal law, without regard to conflict of law rules or principles (whether of the State of Texas or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. Any dispute between the parties that is not subject to arbitration or cannot be heard in small claims court will be resolved in the state or federal courts of the State of Texas and the United States, respectively.
        </p>
      </div>

      <div>
        <h2>
          Modifying and Terminating our Services
        </h2>
        <p>
          We reserve the right to modify our Services or to suspend or stop providing all or portions of our Services to you (including, without limitation, by suspending or terminating your account) at any time. You also have the right to stop using our Services at any time. We are not responsible for any loss or harm related to your inability to access or use our Services.
        </p>
      </div>

      <div>
        <h2>
          Severability
        </h2>
        <p>
          If any provision or part of a provision of these Terms is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from these Terms and does not affect the validity and enforceability of any remaining provisions.
        </p>
      </div>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, func, bool } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
  handleOnChange: func,
  agreement: bool,
  handleSubmitClaimRequest: func,
};

export default TermsOfService;
