import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from '../util/capitalizeFirstLetter';
import axios from './axios-client';

export const createWinery = async (obj, setLoading, prevData, setData, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.post(`winery`, obj);
    const { winery } = data?.data;
    setData([winery, ...prevData]);
    setLoading(false);
    toast.success(data?.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};

export const updateWinery = async (wineryId, obj, setLoading, setData, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.put(`winery/${wineryId}`, obj);
    setData(data?.data.updatedWinery);
    setLoading(false);
    toast.success(data?.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};

export const pinWinery = async (wineryId, setLoading, setData, prevData, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.put(`winery/pine/${wineryId}`);
    const temp = [];
    prevData().forEach(p => {
      if (p._id === data?.data.id) {
        p['searchMeta'].pined = true;
      }
      temp.push(p);
    });
    setData(temp);
    setLoading(false);
    toast.success(data?.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};
export const unpinWinery = async (wineryId, setLoading, setData, prevData, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.put(`winery/unpine/${wineryId}`);
    const temp = [];
    prevData().forEach(p => {
      if (p._id === data?.data.id) {
        p['searchMeta'].pined = false;
      }
      temp.push(p);
    });
    setData(temp);
    setLoading(false);
    toast.success(data?.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};

export const deleteWinery = async (
  id,
  setLoading,
  prevData,
  setData = f => f,
  setError = f => f
) => {
  try {
    setLoading(true);
    const data = await axios.delete(`winery/${id}`);
    const temp = prevData().filter(p => p._id !== data.data.winery._id);
    console.log('data', data.data.winery);
    setData(temp);
    setLoading(false);
    toast.success(data?.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};

export const getWinery = async (wineryId, setLoading, setData, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.get(`winery/${wineryId}`);
    setData(data.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};

export const getMyWinery = async (obj, setLoading, setData, setError = f => f) => {
  const { userId, sharetribeUserId } = obj;
  try {
    setLoading(true);
    const data = await axios.get(
      `winery/my-winery?userId=${userId}&sharetribeUserId=${sharetribeUserId}`
    );
    setData(data.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};

export const getAllWineries = async (setLoading = f => f, setData = f => f, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.get('winery/all-wineries');
    setData(data.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};
export const getPinedWineries = async (
  limit,
  setLoading = f => f,
  setData = f => f,
  setError = f => f
) => {
  try {
    setLoading(true);
    const data = await axios.get(`winery/pin-wineries/${limit}`);
    setData(data.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};

export const searchWineries = async (
  searchValue,
  setLoading = f => f,
  setData = f => f,
  setError = f => f
) => {
  try {
    setLoading(true);
    const searchText = capitalizeFirstLetter(searchValue);
    const data = await axios.get(`winery/search-wineries?searchText=${searchText}`);
    setData(data.data);
    setLoading(false);
    if (data.data.length === 0) {
      toast.info(`We're sorry, we are unable to locate what you searched for! Please try again.`);
    }
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};

export const getWineryBySlug = async (slug, setLoading, setData, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.get(`winery/findBySlug/${slug}`);
    setData(data.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};
export const getWineryBySlugForDuckFile = async (slug) => {
  const data = await axios.get(`winery/findBySlug/${slug}`);
  return data.data;
};

export const uploadWineriesAsCSV = async (
  fd,
  setLoading = f => f,
  setWineriesLoading,
  setWineries,
  setError = f => f
) => {
  try {
    const data = await axios.post(`winery/upload-csv`, fd);
    setLoading(false);
    toast.success(data?.data.message);
    getAllWineries(setWineriesLoading, setWineries);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};

export const makeClaimRequest = async (
  obj,
  setLoading = f => f,
  setData = f => f,
  prevData,
  setError = f => f
) => {
  try {
    setLoading(true);
    const data = await axios.post('claim', obj);
    let temp = prevData;
    if (data.data.claimRequest.winery === temp._id) {
      temp.claimed = 'pending';
    }
    setData(temp);
    setLoading(false);
    toast.success(data.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};

export const claimRequestByAdmin = async (
  obj,
  setLoading = f => f,
  setData = f => f,
  prevData,
  setError = f => f
) => {
  try {
    setLoading(true);
    const data = await axios.post('claim/admin-requests', obj);
    // let temp = prevData;
    // if(data.data.claimRequest.winery === temp._id) {
    //   temp.claimed = "pending"
    // }
    // setData(temp);
    setLoading(false);
    toast.success(data.data.message);
  } catch (error) {
    setLoading(false);
    toast.error(error?.response.data.message);
  }
};
export const getWineryClaimRequests = async (
  setLoading = f => f,
  setData = f => f,
  setError = f => f
) => {
  try {
    setLoading(true);
    const data = await axios.get('claim/requests');
    setData(data.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};

export const approveClaimRequest = async (
  obj,
  setLoading = f => f,
  setData = f => f,
  prevData,
  setSelectedTab,
  setError = f => f
) => {
  try {
    setLoading(true);
    const data = await axios.put(`claim/approve/${obj.claimId}`, obj.data);
    let temp = prevData;
    temp.forEach(d => {
      if (d._id === data.data.updatedClaimReq._id) {
        d.status = data.data.updatedClaimReq.status;
      }
    });
    setData(temp);
    setLoading(false);
    setSelectedTab('claimed');
    toast.success(data.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};

export const rejectClaimRequest = async (
  req,
  setLoading = f => f,
  setData = f => f,
  prevData,
  setSelectedTab,
  setError = f => f
) => {
  const claimId = req._id;
  const userId = req.user;
  try {
    setLoading(true);
    const data = await axios.put(`claim/reject/${claimId}`, { userId });
    let temp = prevData;
    temp.forEach(d => {
      if (d._id === data.data.updatedClaimReq._id) {
        d.status = data.data.updatedClaimReq.status;
      }
    });
    setData(temp);
    setLoading(false);
    toast.success(data.data.message);
    setSelectedTab('rejected');
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};
