import pick from 'lodash/pick';

import config from '../../config';
import { types as sdkTypes, util as sdkUtil, createImageVariantConfig } from '../../util/sdkLoader';
import { getStartOf, addTime } from '../../util/dates';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //


export const SHOW_LISTING_REQUEST = 'app/WinePage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/WinePage/SHOW_LISTING_ERROR';
export const SHOW_LISTING_SUCCESS = 'app/WinePage/SHOW_LISTING_SUCCESS';


// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  showListingLoading: false,
};


const winePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null, showListingLoading: true };
    case SHOW_LISTING_SUCCESS:
      return { ...state, showListingError: null, showListingLoading: false };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload,  showListingLoading: false };
    default:
      return state;
  }
};

export default winePageReducer;

// ================ Action creators ================ //
export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = () => ({
  type: SHOW_LISTING_SUCCESS
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  // dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  return sdk.listings
    .show(params)
    .then(data => {
      console.log('data in single listing', data);
      dispatch(addMarketplaceEntities(data));
      dispatch(showListingSuccess())
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);

  return Promise.all([
    dispatch(showListing(listingId)),
    // dispatch(fetchReviews(listingId))
  ]);
};
