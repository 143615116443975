import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const UserNav = props => {
  const { wineryOwner, className, rootClassName, selectedPageName } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const isWineryOwner =  mounted && wineryOwner;

  const ownerTabs = [
    {
      text: "My Winery",
      selected: selectedPageName === 'WineryPageInfo',
      linkProps: {
        name: 'WineryPageInfo',
      },
    },
    {
      text: 'Manage wines',
      selected: selectedPageName === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    // {
    //   text: 'Manage wine clubs',
    //   selected: selectedPageName === 'ManageWineClubPage',
    //   linkProps: {
    //     name: 'ManageWineClubPage',
    //   },
    // },
    {
      text: 'Subscriptions',
      selected: selectedPageName === 'ManageSubscriptionsAndSettingsPage',
      linkProps: {
        name: 'ManageSubscriptionsAndSettingsPage',
      },
    },
  ]
  const wineryOwnerTabs = isWineryOwner ? ownerTabs : [];
  const tabs = [
    ...wineryOwnerTabs,
    {
      text: 'My Favorites',
      selected: selectedPageName === 'MyFavoritesPage',
      disabled: false,
      linkProps: {
        name: 'MyFavoritesPage',
      },
    },
    {
      text: 'My Reviews',
      selected: selectedPageName === 'MyReviewsPage',
      disabled: false,
      linkProps: {
        name: 'MyReviewsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  return (
    <div>
      <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
    </div>
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};


const mapStateToProps = state => {
  const { admin, wineryOwner } = state.Auth;
  return {
    wineryOwner
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
  ),
)(UserNav);

// export default UserNav;
