/**
 * This file contains Action types, Action creators, and reducer of global
 * ResearchWineriesFilters. Global actions can be used in multiple pages.
 * We are following Ducks module proposition:
 * https://github.com/erikras/ducks-modular-redux
 */

// Actions
export const CHANGE_SELECTED_REGIONS = 'app/ResearchWineriesFilters/CHANGE_SELECTED_REGIONS';
export const CHANGE_SELECTED_CITIES = 'app/ResearchWineriesFilters/CHANGE_SELECTED_CITIES';
export const CHANGE_SELECTED_SCORE = 'app/ResearchWineriesFilters/CHANGE_SELECTED_SCORE';
export const CHANGE_SELECTED_VARIETALS = 'app/ResearchWineriesFilters/CHANGE_SELECTED_VARIETALS';
export const CHANGE_PRODUCTION_RANGE = 'app/ResearchWineriesFilters/CHANGE_PRODUCTION_RANGE';
export const CHANGE_AFFORDABLE_SCORE = 'app/ResearchWineriesFilters/CHANGE_AFFORDABLE_SCORE';
export const CHANGE_AMBIANCE_SCORE = 'app/ResearchWineriesFilters/CHANGE_AMBIANCE_SCORE';

export const CHANGE_CUSTOMER_SERVICE_SCORE =
  'app/ResearchWineriesFilters/CHANGE_CUSTOMER_SERVICE_SCORE';
export const CHANGE_DOGS_FRIENDLY = 'app/ResearchWineriesFilters/CHANGE_DOGS_FRIENDLY';
export const CHANGE_KIDS_FRIENDLY = 'app/ResearchWineriesFilters/CHANGE_KIDS_FRIENDLY';
export const CHANGE_FOOD_OFFERINGS_SCORE =
  'app/ResearchWineriesFilters/CHANGE_FOOD_OFFERINGS_SCORE';
export const CHANGE_RESERVATIONS_REQUIRED =
  'app/ResearchWineriesFilters/CHANGE_RESERVATIONS_REQUIRED';
export const CHANGE_VARIETY_SCORE = 'app/ResearchWineriesFilters/CHANGE_VARIETY_SCORE';
export const CHANGE_QUALITY_SCORE = 'app/ResearchWineriesFilters/CHANGE_QUALITY_SCORE';
export const CHANGE_SEARCH_VALUE = 'app/ResearchWineriesFilters/CHANGE_SEARCH_VALUE';
export const CHANGE_STATE_VALUE = 'app/ResearchWineriesFilters/CHANGE_STATE_VALUE';

export const CHANGE_WINERIES = 'app/ResearchWineriesFilters/CHANGE_WINERIES';
export const CHANGE_WINERIES_TO_DISPLAY = 'app/ResearchWineriesFilters/CHANGE_WINERIES_TO_DISPLAY';
export const CHANGE_WINERIES_LOADING = 'app/ResearchWineriesFilters/CHANGE_WINERIES_LOADING';
export const CHANGE_CURRENT_PAGE = 'app/ResearchWineriesFilters/CHANGE_CURRENT_PAGE';
export const CHANGE_PAGES = 'app/ResearchWineriesFilters/CHANGE_PAGES';

// Reducer
export default function reducer(
  state = {
    selectedRegions: [],
    searchValue: '',
    selectedScore: 'high',
    selectedCities: [],
    qualityScore: 0,
    varietyScore: 0,
    ambianceScore: 0,
    affordableScore: 0,
    foodOfferingsScore: 0,
    customerServiceScore: 0,
    productionRange: [],
    selectedVarietals: [],
    reservationsRequired: false,
    dogsFriendly: false,
    kidsFriendly: false,
    stateValue: '',
    wineriesLoading: false,
    wineries: [],
    wineriesToDisplay: [],
    pages: 0,
    currentPage: 1,
  },
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_SELECTED_REGIONS: {
      return {
        ...state,
        selectedRegions: payload,
      };
    }
    case CHANGE_STATE_VALUE: {
      return {
        ...state,
        stateValue: payload,
      };
    }

    case CHANGE_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: payload,
      };
    }
    case CHANGE_SELECTED_CITIES: {
      return {
        ...state,
        selectedCities: payload,
      };
    }

    case CHANGE_SELECTED_SCORE: {
      return {
        ...state,
        selectedScore: payload,
      };
    }

    case CHANGE_SELECTED_VARIETALS: {
      return {
        ...state,
        selectedVarietals: payload,
      };
    }
    case CHANGE_PRODUCTION_RANGE: {
      return {
        ...state,
        productionRange: payload,
      };
    }
    case CHANGE_KIDS_FRIENDLY: {
      return {
        ...state,
        kidsFriendly: payload,
      };
    }
    case CHANGE_DOGS_FRIENDLY: {
      return {
        ...state,
        dogsFriendly: payload,
      };
    }
    case CHANGE_RESERVATIONS_REQUIRED: {
      return {
        ...state,
        reservationsRequired: payload,
      };
    }
    case CHANGE_QUALITY_SCORE: {
      return {
        ...state,
        qualityScore: payload,
      };
    }
    case CHANGE_VARIETY_SCORE: {
      return {
        ...state,
        varietyScore: payload,
      };
    }
    case CHANGE_AMBIANCE_SCORE: {
      return {
        ...state,
        ambianceScore: payload,
      };
    }

    case CHANGE_AFFORDABLE_SCORE: {
      return {
        ...state,
        affordableScore: payload,
      };
    }
    case CHANGE_FOOD_OFFERINGS_SCORE: {
      return {
        ...state,
        foodOfferingsScore: payload,
      };
    }
    case CHANGE_CUSTOMER_SERVICE_SCORE: {
      return {
        ...state,
        customerServiceScore: payload,
      };
    }
    case CHANGE_WINERIES: {
      return {
        ...state,
        wineries: payload,
      };
    }
    case CHANGE_WINERIES_LOADING: {
      return {
        ...state,
        wineriesLoading: payload,
      };
    }
    case CHANGE_WINERIES_TO_DISPLAY: {
      return {
        ...state,
        wineriesToDisplay: payload,
      };
    }
    case CHANGE_PAGES: {
      return {
        ...state,
        pages: payload,
      };
    }
    case CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload,
      };
    }
    default:
      return state;
  }
}
// Action types
export const changeRegionFilter = region => ({ type: CHANGE_SELECTED_REGIONS, payload: region });
export const changeCitiesFilter = cities => ({ type: CHANGE_SELECTED_CITIES, payload: cities });
export const changeSortByRatingFilter = score => ({ type: CHANGE_SELECTED_SCORE, payload: score });
export const changeVarietalsFilter = varietals => ({
  type: CHANGE_SELECTED_VARIETALS,
  payload: varietals,
});
export const changeProductionRangeFilter = productionRange => ({
  type: CHANGE_PRODUCTION_RANGE,
  payload: productionRange,
});
export const changeAffordableScoreFilter = affordableScore => ({
  type: CHANGE_AFFORDABLE_SCORE,
  payload: affordableScore,
});
export const changeAmbianceScoreFilter = ambianceScore => ({
  type: CHANGE_AMBIANCE_SCORE,

  payload: ambianceScore,
});
export const changeCustomerServiceScoreFilter = customerServiceScore => ({
  type: CHANGE_CUSTOMER_SERVICE_SCORE,
  payload: customerServiceScore,
});
export const changeFoodOfferingsScoreFilter = foodOfferingsScore => ({
  type: CHANGE_FOOD_OFFERINGS_SCORE,
  payload: foodOfferingsScore,
});
export const changeReservationsRequiredFilter = reservationsRequired => ({
  type: CHANGE_RESERVATIONS_REQUIRED,
  payload: reservationsRequired,
});
export const changeDogsFriendlyFilter = dogsFriendly => ({
  type: CHANGE_DOGS_FRIENDLY,
  payload: dogsFriendly,
});
export const changeKidsFriendlyFilter = kidsFriendly => ({
  type: CHANGE_KIDS_FRIENDLY,
  payload: kidsFriendly,
});
export const changeQualityScoreFilter = qualityScore => ({
  type: CHANGE_QUALITY_SCORE,
  payload: qualityScore,
});
export const changeVarietyScoreFilter = varietyScore => ({
  type: CHANGE_VARIETY_SCORE,
  payload: varietyScore,
});
export const changeSearchValueFilter = searchValue => ({
  type: CHANGE_SEARCH_VALUE,
  payload: searchValue,
});

export const changeStateValueFilter = stateValue => ({
  type: CHANGE_STATE_VALUE,
  payload: stateValue,
});

export const changeWineriesFilter = wineries => ({
  type: CHANGE_WINERIES,
  payload: wineries,
});
export const changeWineriesLoadingFilter = wineriesLoading => ({
  type: CHANGE_WINERIES_LOADING,
  payload: wineriesLoading,
});
export const changeWineriesToDisplayFilter = wineriesToDisplay => ({
  type: CHANGE_WINERIES_TO_DISPLAY,
  payload: wineriesToDisplay,
});

export const changePagesFilter = pages => ({
  type: CHANGE_PAGES,
  payload: pages,
});
export const changeCurrentPageFilter = page => ({
  type: CHANGE_CURRENT_PAGE,
  payload: page,
});
// Selectors
export const getSelectedRegions = state => state.selectedRegions;
export const getSelectedCities = state => state.selectedCities;
export const getSelectedScore = state => state.selectedScore;
export const getSelectedVarietals = state => state.selectedVarietals;
export const getProductionRange = state => state.productionRange;
export const getAffordableScore = state => state.affordableScore;
export const getAmbianceScore = state => state.ambianceScore;
export const getCustomerServiceScore = state => state.customerServiceScore;
export const getFoodOfferingsScore = state => state.foodOfferingsScore;
export const getReservationsRequired = state => state.reservationsRequired;
export const getDogsFriendly = state => state.dogsFriendly;
export const getKidsFriendly = state => state.kidsFriendly;
export const getQualityScore = state => state.qualityScore;
export const getVarietyScore = state => state.varietyScore;
export const getSearchValue = state => state.searchValue;
export const getStateValue = state => state.StateValue;
export const getWineries = state => state.wineries;
export const getWineriesLoading = state => state.wineriesLoading;
export const getWineriesToDisplay = state => state.wineriesToDisplay;
export const getPages = state => state.pages;
export const getCurrentPage = state => state.currentPage;
