import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';

import { intlShape } from '../../../util/reactIntl';

import { propTypes } from '../../../util/types';
import {
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ResponsiveImage,
  Button,
} from '../../../components';
//import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;
import css from './TopbarDesktop.module.css';
import { BasketIcon } from '../../../assets';
import { BottleIcon } from '../../../assets';
import { DeleteIcon } from '../../../assets';
import { getListingsById, loadData } from '../../../containers/CartPage/CartPage.duck';
import _ from 'lodash';
import { compose } from 'redux';
import { useHistory, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { formatCurrencyMajorUnit, formatMoney } from '../../../util/currency';
import { removeFromStore } from '../../../util/cartStorage';
import config from '../../../config';

const FunctionReturn = props => {
  const {
    intl,

    wines,
  } = props;
  const [mounted, setMounted] = useState(false);

  const history = useHistory();
  useEffect(() => {
    setMounted(true);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadData(undefined, {}));
  }, []);

  const cartWines = _.groupBy(wines, w => w?.attributes.publicData.wineryName);
  const [subtotal, setSubtotal] = useState('');
  const [totalQty, setTotalQty] = useState(0);
  useEffect(() => {
    if (wines.length > 0) {
      const newWines = wines.map(wine => wine);
      // console.log('newWines', newWines);
      let newSubTotal = 0;
      let newtotalQty = 0;
      for (const wine of newWines) {
        newtotalQty = newtotalQty + wine.quantity;
        newSubTotal += wine.attributes.price.amount * wine.quantity;
      }
      setTotalQty(newtotalQty);
      setSubtotal(formatMoney(intl, new Money(newSubTotal, config.currency)));
    }
  }, [wines]);

  const inboxLink = (
    <>
      {/* <SearchIcon rootClassName={css.SearchIcon} /> */}

      <Menu>
        <MenuLabel className={css.inboxLink} isOpenClassName={css.cartMenuIsOpen}>
          <span>
            <BasketIcon style={{ fill: 'none' }} />
            {totalQty}
          </span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="MyCart">
            {Object.keys(cartWines).length === 0 ? (
              <div
                style={{
                  width: '200px',
                  height: '100px',
                  textAlign: 'center',
                }}
              >
                <BottleIcon style={{ fill: 'none' }} />
                <p>Your cart is empty.</p>
              </div>
            ) : (
              <div style={{ width: '340px', overflowY: 'scroll', height: '500px' }}>
                <div>
                  <p style={{ textAlign: 'center', height: '5%' }}>
                    Your cart: {wines.length} items from {Object.keys(cartWines).length} wineries
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '100%',
                    height: '95%',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="my-box">
                    {Object.keys(cartWines).map(k => (
                      <div className={css.itemsWrapper}>
                        <div style={{ minWidth: '100%', marginTop: 10 }}>
                          {cartWines[k].map(wine => {
                            const wineTitle =
                              wine?.attributes?.publicData?.vintage !== undefined
                                ? wine?.attributes?.publicData?.vintage +
                                  ' ' +
                                  wine?.attributes.title
                                : `NV ${wine?.attributes.title}`;
                            const firstImage =
                              wine.images && wine.images.length > 0 ? wine.images[0] : null;
                            const formattedPrice = formatMoney(intl, wine?.attributes.price);

                            return (
                              <div className={css.cartWrapper}>
                                <div
                                  className={css.cartContainer}
                                  style={{
                                    padding: '10px 20px',
                                    width: '100%',
                                  }}
                                >
                                  <div className={css.cardImgWrapper} style={{ width: '20%' }}>
                                    <ResponsiveImage
                                      rootClassName={css.rootForImage}
                                      alt={wineTitle}
                                      image={firstImage}
                                      variants={['scaled-small']}
                                      style={{
                                        width: '100%',
                                      }}
                                      sizes="(max-width: 481px) "
                                    />
                                  </div>

                                  <div
                                    className={css.titleWrapper}
                                    style={{
                                      width: '80%',
                                    }}
                                  >
                                    <h3 className={css.winerName}>{k}</h3>
                                    <p>{wine.attributes.title}</p>
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <span className={css.winerName}>{formattedPrice}</span>
                                      <span className={css.winerName}>Qty:{wine.quantity}</span>
                                      <span className={css.winerName}>
                                        <DeleteIcon
                                          style={{ fill: 'none' }}
                                          onClick={() => {
                                            // removing from cart
                                            removeFromStore(wine.id.uuid);
                                            dispatch(loadData(undefined, {}));
                                          }}
                                          className={css.deleteIcon}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      minWidth: '100%',
                      backgroundColor: '#F2F1EF',
                      padding: '17px 15px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        minWidth: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <h3>Sub-total</h3>
                      <h3>{subtotal}</h3>
                    </div>
                    <Button
                      onClick={() => {
                        history.push('/cart');
                      }}
                      rootClassName={css.viewCartButton}
                    >
                      View Cart
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </MenuItem>
        </MenuContent>
      </Menu>
    </>
  );

  return inboxLink;
};

FunctionReturn.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

FunctionReturn.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
  } = state.CartPage;
  const wines = getListingsById(state, currentPageResultIds);

  return {
    currentUser,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    wines,
  };
};

export default compose(withRouter, connect(mapStateToProps), injectIntl)(FunctionReturn);
