import React, { useState } from 'react';
import { useMemo } from 'react';
import css from './Bottle.module.css';

const Bottle = ({ percentage, id}) => {
  return (
    <div style={{ marginRight: "10px" }}>
      <svg
        width="7"
        height="20"
        viewBox="0 0 7 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
      <linearGradient id={id} x1="0.5" y1="1" x2="0.5" y2="0">
        <stop offset="0%" stopOpacity="1" stopColor="#78264F" />
        <stop offset={`${percentage}%`} stopOpacity="1" stopColor="#78264F" />
        <stop offset="0%" stopColor="#f2f1ef" />
        <stop offset="100%" stopColor="#f2f1ef" />
      </linearGradient>
      <path
        d="M2.38649 0.375H4.61351V3.96478C4.61351 4.99951 4.91996 5.96052 5.52432 6.83953L5.52423 6.8396L5.52978 6.84725L5.84879 7.28707C6.36747 8.04304 6.625 8.88922 6.625 9.76722V19.625H0.375V9.76722C0.375 8.89132 0.631399 8.04337 1.15332 7.25003L1.47022 6.81312L1.47023 6.81312L1.47136 6.81154C2.08148 5.96113 2.38649 4.96271 2.38649 3.96478V0.375Z"
        fill={`url(#${id})`}
        stroke="#78264F"
        strokeWidth="0.75"
      />
      </svg>
    </div>
  );
};
export default Bottle;

export const BottleRatingReview = ({score = 0, uniqId = 'review'}) => {
  const [ratings, setRatings] = useState([])

  useMemo(() => {
    let tempScore = [];
    for (let i = 1; i <= 5; i++) {
      let tempPercent;

      if(score >= 1){
        tempPercent = 100
      } else if(score <= 0){
        tempPercent = 0
      } else if(Number(score.toFixed(1)) === 0.1) {
        tempPercent = 10
      } else if(Number(score.toFixed(1)) === 0.2) {
        tempPercent = 20
      } else if(Number(score.toFixed(1)) === 0.3) {
        tempPercent = 30
      } else if(Number(score.toFixed(1)) === 0.4) {
        tempPercent = 40
      } else if(Number(score.toFixed(1)) === 0.5) {
        tempPercent = 50
      } else if(Number(score.toFixed(1)) === 0.6) {
        tempPercent = 60
      } else if(Number(score.toFixed(1)) === 0.7) {
        tempPercent = 70
      } else if(Number(score.toFixed(1)) === 0.8) {
        tempPercent = 80
      } else if(Number(score.toFixed(1)) === 0.9) {
        tempPercent = 90
      } else {
        tempPercent = 100
      }

      tempScore = [
        ...tempScore,
        {
          percentage: tempPercent,
          uniqId: uniqId + "_" + Math.random() * 100 + "_" + i,
        }
      ]
      score--;
    }
    setRatings(tempScore);
  }, [score]);

  return (
    <div style={{display: 'flex', }}>
      {ratings.map((r, index) => {
        return <Bottle key={index} percentage={r.percentage} id={r.uniqId} />
      })}
    </div>
  )
};
