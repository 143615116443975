import React from 'react';
import css from './Upload.module.css';
import classNames from 'classnames';
import { IconSpinner } from '..';


const UploadImage = ({text, handleOnChangeImage, uploadPhotoLoading, className}) => {
  const rootClasses = classNames(css.root, className ? className : "");
  return (
    <div>
      <label className={rootClasses}>
        {uploadPhotoLoading ? (
          <IconSpinner />
        ) : (
          <>
            {text}
            <input
              type="file"
              // id={`upload-${id}`}
              className={css.uploadInput}
              accept="image/*"
              onChange={handleOnChangeImage}
              hidden
            />
          </>
        )}
      </label>
    </div>
  )
}

export default UploadImage;
