/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';
//const { types } = require('sharetribe-flex-sdk');
//const { Money } = types;
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';

import css from './TopbarMobileMenu.module.css';
import ExternalLink from '../../ExternalLink/ExternalLink';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
//import { getListingsById } from '../../../containers/CartPage/CartPage.duck';
import { useState } from 'react';
import { formatMoney } from '../../../util/currency';
import config from '../../../config';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    admin,
    wineryOwner,
    wines,
    intl,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  // const notificationCountBadge =
  //   notificationCount > 0 ? (
  //     <NotificationBadge className={css.notificationBadge} count={notificationCount} />
  //   ) : null;

  // const signup = (
  //   <NamedLink name="SignupPage" className={css.signupLink}>
  //     <FormattedMessage id="TopbarMobileMenu.signupLink" />
  //   </NamedLink>
  // );

  // const login = (
  //   <NamedLink name="LoginPage" className={css.loginLink}>
  //     <FormattedMessage id="TopbarMobileMenu.loginLink" />
  //   </NamedLink>
  // );

  //const cartWines = _.groupBy(wines, w => w?.attributes.publicData.wineryName);
  // const [subtotal, setSubtotal] = useState('');
  // const [totalQty, setTotalQty] = useState(0);
  // useEffect(() => {
  //   if (wines.length > 0) {
  //     const newWines = wines.map(wine => wine);
  //     // console.log('newWines', newWines);
  //     let newSubTotal = 0;
  //     let newtotalQty = 0;
  //     for (const wine of newWines) {
  //       newtotalQty = newtotalQty + wine.quantity;
  //       newSubTotal += wine.attributes.price.amount * wine.quantity;
  //     }
  //     setTotalQty(newtotalQty);
  //     setSubtotal(formatMoney(intl, new Money(newSubTotal, config.currency)));
  //   }
  // }, [wines]);

  // const signupOrLogin = (
  //   <span className={css.authenticationLinks}>
  //     <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
  //   </span>
  // );
  const unautLoginSignup = !isAuthenticated && (
    <div className={css.root}>
      <div className={css.content}>
{/*         <div className={css.authenticationGreeting}>
          <FormattedMessage
            id="TopbarMobileMenu.unauthorizedGreeting"
            values={{ lineBreak: <br />, signupOrLogin }}
          />
        </div> */}

 {/*        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ResearchWineries'))}
          name="ResearchWineries"
        >
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.researchWineries" />
          </span>
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('BuyWine'))}
          name="BuyWine"
        >
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.buyWine" />
          </span>
        </NamedLink> */}
        {/* <NamedLink className={classNames(css.navigationLink, currentPageClass('ResearchWineries'))} name="WineClubs">
          Join Wine Clubs
        </NamedLink> */}
        <ExternalLink
          href="https://blog.sommly.com/"
          className={classNames(css.navigationLink, currentPageClass('ResearchWineries'))}
        >
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.blogPage" />
          </span>
        </ExternalLink>
        {/* <ExternalLink href="https://sommly.com/about/" className={classNames(css.navigationLink, currentPageClass('ResearchWineries'))}>
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.aboutPage" />
          </span>
        </ExternalLink> */}
       {/* <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
          name="AboutPage"
        >
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.aboutPage" />
          </span>
      </NamedLink>*/}
{/*         <NamedLink
          className={classNames(css.navigationLink, currentPageClass('WhysommlyPage'))}
          name="WhysommlyPage"
        >
          <span className={css.navMenuLinkText}>Why Sommly</span>
        </NamedLink> */}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ClubsPage'))}
          name="ClubsPage"
        >
          <span className={css.navMenuLinkText}>Curated Wines</span>
        </NamedLink>
       {/* <NamedLink
          className={classNames(css.navigationLink, currentPageClass('WineryPlansPage'))}
          name="WineryPlansPage"
      >
          <span className={css.navMenuLinkText}>Winery Plans</span>
        </NamedLink>*/}
      </div>
    </div>
  );

  const authLinks = isAuthenticated && (
    <>
      {/* <AvatarLarge className={css.avatar} user={currentUser} /> */}
      <div className={css.content}>
{/*         <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ResearchWineries'))}
          name="ResearchWineries"
        >
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.researchWineries" />
          </span>
        </NamedLink> */}
{/*         <NamedLink
          className={classNames(css.navigationLink, currentPageClass('BuyWine'))}
          name="BuyWine"
        >
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.buyWine" />
          </span>
        </NamedLink> */}
        {/* <NamedLink className={classNames(css.navigationLink, currentPageClass('ResearchWineries'))} name="WineClubs">
          Join Wine Clubs
        </NamedLink> */}
        <ExternalLink
          href="https://blog.sommly.com/"
          className={classNames(css.navigationLink, currentPageClass('ResearchWineries'))}
        >
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.blogPage" />
          </span>
        </ExternalLink>
        {/* <ExternalLink href="https://sommly.com/about/" className={classNames(css.navigationLink, currentPageClass('ResearchWineries'))}>
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.aboutPage" />
          </span>
        </ExternalLink> */}
        {/*<NamedLink
          className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
          name="AboutPage"
        >
          <span className={css.navMenuLinkText}>
            <FormattedMessage id="TopbarDesktop.aboutPage" />
          </span>
      </NamedLink>*/}
{/*         <NamedLink
          className={classNames(css.navigationLink, currentPageClass('WhysommlyPage'))}
          name="WhysommlyPage"
        >
          <span className={css.navMenuLinkText}>Why Sommly</span>
        </NamedLink> */}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ClubsPage'))}
          name="ClubsPage"
        >
          <span className={css.navMenuLinkText}>Curated Wines</span>
        </NamedLink>
        {/*<NamedLink
          className={classNames(css.navigationLink, currentPageClass('WineryPlansPage'))}
          name="WineryPlansPage"
        >
          <span className={css.navMenuLinkText}>Winery Plans</span>
      </NamedLink>*/}
{/*         {admin ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('DhasboardPage'))}
            name="DhasboardPage"
            params={{ tab: 'wineries' }}
          >
            <span className={css.navMenuLinkText}>Dashboard</span>
          </NamedLink>
        ) : null} */}

{/*         <NamedLink
          className={classNames(css.navigationLink, css.inbox, currentPageClass('InboxPage'))}
          name="CartPage"
          // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          My Shopping Cart
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink> */}
        {/* <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink> */}

  {/*       <NamedLink
          className={classNames(css.navigationLink, currentPageClass('MyFavoritesPage'))}
          name="MyFavoritesPage"
        >
          <span className={css.menuItemBorder} />
          My Favorites
        </NamedLink>

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('MyReviewsPage'))}
          name="MyReviewsPage"
        >
          <span className={css.menuItemBorder} />
          My Reviews
        </NamedLink>

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink> */}
        <div className={css.spacer} />
      </div>
    </>
  );

  return (
    <>
      <div className={css.root}>
        {unautLoginSignup}

        {/* {authLinks} */}

        {/* {wineryOwner ? (
          <div className={css.footer}>
            <NamedLink className={css.createNewListingLink} name="WineryPageInfo">
              My Winery
            </NamedLink>
            <NamedLink className={css.createNewListingLink} name="ManageListingsPage">
              My Wines
            </NamedLink>
            <NamedLink
              className={css.createNewListingLink}
              name="ManageSubscriptionsAndSettingsPage"
            >
              My Subscribtions
            </NamedLink>
          </div>
        ) : null} */}
      </div>
    </>
  );
};

// TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

// const { bool, func, number, string } = PropTypes;

//TopbarMobileMenu.propTypes = {
  // isAuthenticated: bool.isRequired,
  // currentUserHasListings: bool.isRequired,
  // currentUser: propTypes.currentUser,
  // currentPage: string,
  // notificationCount: number,
  // onLogout: func.isRequired,
//};

const mapStateToProps = state => {
  //const { currentUser } = state.user;
  // const {
  //   currentPageResultIds,
  //   pagination,
  //   queryInProgress,
  //   queryListingsError,
  //   queryParams,
  // } = state.CartPage;
 // const wines = getListingsById(state, currentPageResultIds);

  return {
    // currentUser,
    // pagination,
    // queryInProgress,
    // queryListingsError,
    // queryParams,
   // wines,
  };
};

export default compose(withRouter, connect(mapStateToProps), injectIntl)(TopbarMobileMenu);
