import { toast } from 'react-toastify';
import axios from './axios-client';

export const addNewReview = async (obj , setLoading, setData, prevData, setTosModelOpen, setError = f => f) => {
  try {
    setLoading(true)
    const data = await axios.post(`review/add`, obj);
    const {createdReview} = data.data;
    setData([createdReview, ...prevData]);
    setLoading(false);
    setTosModelOpen(false);
    toast.success(data?.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};
export const getWineryReviews = async ( wineryId, setLoading = f => f, setData = f => f, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.get(`review/winery/${wineryId}`);
    setData(data.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};
export const getMyOwnReviews = async ( userId, setLoading = f => f, setData = f => f, setError = f => f) => {
  try {
    setLoading(true);
    const data = await axios.get(`review/user/${userId}`);
    setData(data.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
};
export const getProfileReviews = async ( userId) => {
  try {
    const data = await axios.get(`review/user/${userId}`);
    return data.data
  } catch (error) {
    console.log('error', error);
  }
};


export const editReview = async (
  obj,
  reviewId,
  setLoading,
  setData,
  prevData,
  setTosModelOpen,
  setError = f => f
) => {
  try {
    setLoading(true)
    const data = await axios.put(`review/edit/${reviewId}`, obj);;
    let temp = []
    prevData.forEach(d => {
      if (d._id === data?.data.updatedReview._id) d = data?.data.updatedReview
      temp.push(d)
    });
    setData(temp);
    setLoading(false);
    setTosModelOpen(false);
    toast.success(data?.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.response.data.message);
  }
};

export const deleteReview = async (reviewId, setLoading, setData = f => f, prevData, setOpenDeleteModal, setError = f => f) => {
  try {
    setLoading(true)
    const data = await axios.delete(`review/remove/${reviewId}`);
    let temp = prevData;
    temp = temp.filter(t => t._id !== data?.data.reviewId)
    setData(temp);
    setLoading(false);
    setOpenDeleteModal(false);
    toast.success(data?.data.message);
  } catch (error) {
    setError(error);
    setLoading(false);
    toast.error(error.message);
  }
};
