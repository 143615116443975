import config from '../../config';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import axios from '../../services/axios-client';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const FETCH_WINES_REQUEST = 'app/ResearchWinesPage/FETCH_WINES_REQUEST';
export const FETCH_WINES_SUCCESS = 'app/ResearchWinesPage/FETCH_WINES_SUCCESS';
export const FETCH_WINES_ERROR = 'app/ResearchWinesPage/FETCH_WINES_ERROR';
export const FETCH_ALL_WINES_SUCCESS = 'app/ResearchWinesPage/FETCH_ALL_WINES_SUCCESS';

export const FETCH_STATES_REQUEST = 'app/ResearchWinesPage/FETCH_STATES';
export const FETCH_STATES_SUCCESS = 'app/ResearchWinesPage/FETCH_STATES_SUCCESS';
export const FETCH_STATES_ERROR = 'app/ResearchWinesPage/FETCH_STATES_ERROR';

export const FETCH_VARIETALS_REQUEST = 'app/ResearchWinesPage/FETCH_VARIETALS';
export const FETCH_VARIETALS_SUCCESS = 'app/ResearchWinesPage/FETCH_VARIETALS_SUCCESS';
export const FETCH_VARIETALS_ERROR = 'app/ResearchWinesPage/FETCH_VARIETALS_ERROR';

export const ADD_ENTITIES = 'app/ResearchWinesPage/ADD_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  entities: {},

  states: [],
  statesLoading: false,
  statesError: null,

  varietals: [],
  varietalsLoading: false,
  varietalsError: null,

  allWines: [],
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    entities: updatedEntities({ ...state.entities }, apiResponse),
  };
};

const researchWinesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_WINES_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_WINES_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_WINES_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryListingsError: payload,
      };
    case FETCH_ALL_WINES_SUCCESS:
      return {
        ...state,
        allWines: payload.data.data,
      };
    case ADD_ENTITIES:
      return merge(state, payload);

    case FETCH_STATES_REQUEST:
      return {
        ...state,
        states: [],
        statesLoading: true,
        statesError: null,
      };
    case FETCH_STATES_SUCCESS:
      return {
        ...state,
        states: payload.states,
        statesLoading: false,
        statesError: null,
      };
    case FETCH_STATES_ERROR:
      return {
        ...state,
        states: [],
        statesLoading: false,
        statesError: payload.error,
      };

    case FETCH_VARIETALS_REQUEST:
      return {
        ...state,
        varietals: [],
        varietalsLoading: true,
        varietalsError: null,
      };
    case FETCH_VARIETALS_SUCCESS:
      return {
        ...state,
        varietals: payload.varietals,
        varietalsLoading: false,
        varietalsError: null,
      };
    case FETCH_VARIETALS_ERROR:
      return {
        ...state,
        varietals: [],
        varietalsLoading: false,
        varietalsError: payload.error,
      };

    default:
      return state;
  }
};

export default researchWinesPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsById = (state, listingIds) => {
  const { entities } = state.ResearchWinesPage;

  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addEntities = sdkResponse => ({
  type: ADD_ENTITIES,
  payload: sdkResponse,
});

export const queryWineryListingsRequest = queryParams => ({
  type: FETCH_WINES_REQUEST,
  payload: { queryParams },
});

export const queryWineryListingsSuccess = response => ({
  type: FETCH_WINES_SUCCESS,
  payload: { data: response.data },
});


export const queryWineryListingsError = e => ({
  type: FETCH_WINES_ERROR,
  error: true,
  payload: e,
});

export const allWinesSuccess = response => ({
  type: FETCH_ALL_WINES_SUCCESS,
  payload: { data: response.data },
});

export const fetchStatesRequest = () => ({
  type: FETCH_STATES_REQUEST,
});

export const fetchStatesSuccess = states => ({
  type: FETCH_STATES_SUCCESS,
  payload: { states },
});

export const fetchStatesError = e => ({
  type: FETCH_STATES_ERROR,
  payload: { error: e },
});

export const fetchVarietalsRequest = () => ({
  type: FETCH_VARIETALS_REQUEST,
});

export const fetchVarietalsSuccess = varietals => ({
  type: FETCH_VARIETALS_SUCCESS,
  payload: { varietals },
});

export const fetchVarietalsError = e => ({
  type: FETCH_VARIETALS_ERROR,
  payload: { error: e },
});

// Throwing error for new (loadData may need that info)
export const wineryListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryWineryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, per_page: perPage };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addEntities(response));
      dispatch(queryWineryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryWineryListingsError(storableError(e)));
      throw e;
    });
};

export const allWines = queryParams => (dispatch, getState, sdk) => {
  return sdk.listings
    .query({})
    .then(response => {
      dispatch(allWinesSuccess(response));
      return response;
    })
    .catch(e => {
      throw e;
    });
};

export const getAllStates = () => dispatch => {
  dispatch(fetchStatesRequest());
  return axios
    .get(`state`)
    .then(data => {
      dispatch(fetchStatesSuccess(data.data));
    })
    .catch(err => {
      dispatch(fetchStatesError(error));
    });
};

export const getAllVarietals = () => dispatch => {
  dispatch(fetchVarietalsRequest());
  return axios
    .get(`varietal`)
    .then(data => {
      dispatch(fetchVarietalsSuccess(data.data));
    })
    .catch(err => {
      dispatch(fetchVarietalsError(error));
    });
};

const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
const aspectRatio = aspectHeight / aspectWidth;
const customParams = {
  perPage: RESULT_PAGE_SIZE,
  include: ['images', 'currentStock'],
  'fields.image': [
    // Scaled variants for large images
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // // Cropped variants for listing thumbnail images
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
    `variants.${variantPrefix}-4x`,
    `variants.${variantPrefix}-6x`,
  ],
  ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
  ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
  ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
};

export const queryListings = query => {
  return wineryListings({
    ...query,
    page: 1,
    ...customParams,
  });
};

export const loadData = (params, search) => dispatch => {
  const queryParams = parse(search);
  var page = queryParams.page || 1;

  const qParams = {
    ...queryParams,
    page,
    ...customParams,
    pub_state: ["Texas"]
  };

  const qParams2 = {
    ...queryParams,
    page,
    ...customParams,
    pub_state: ["Oregon"]
  };

  page = queryParams.page || 2;

  const qParams3 = {
    ...queryParams,
    page,
    ...customParams,
    pub_state: ["Texas"]
  };



  

  return Promise.all([
    dispatch(getAllStates()),
    dispatch(getAllVarietals()),
    dispatch(allWines(qParams)),
    dispatch(wineryListings(qParams)),
    dispatch(wineryListings(qParams2)),
    dispatch(wineryListings(qParams3)),
  ]);
};
