import axios from "axios";

// const rootUrl = 'http://localhost:5000';
// const rootUrl = 'https://glacial-hollows-83872.herokuapp.com';
// const baseUrl = `${rootUrl}/api/v1/`;
const baseUrl = process.env.REACT_APP_EXPRESS_SERVER_BASE_URL;

const instance = axios.create({
  baseURL: baseUrl,
  // headers: {'Content-Type': 'application/json'},
})

//TODO: NEED remove this code
if (typeof window !== 'undefined') {
  const token = localStorage.getItem('express_user_token');
  if(token) {
    instance.defaults.headers.common["Authorization"] = token;
  } else {
    instance.defaults.headers.common["Authorization"] = "";
  }
}


export default instance;




