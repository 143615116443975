import config from '../../config';
import { getWinesInCart } from '../../util/cartStorage';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_CART_LISTINGS_REQUEST = 'app/CartPage/FETCH_CART_LISTINGS_REQUEST';
export const FETCH_CART_LISTINGS_SUCCESS = 'app/CartPage/FETCH_CART_LISTINGS_SUCCESS';
export const FETCH_CART_LISTINGS_ERROR = 'app/CartPage/FETCH_CART_LISTINGS_ERROR';

export const ADD_CART_WINES_ENTITIES = 'app/CartPage/ADD_CART_WINES_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  entities: {},
};

const resultIds = data => {
  return data.data.map(l => {
    return {
      id: l.id,
      quantity: l.quantity,
      isOwnListing: l.isOwnListing,
    };
  });
};

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    entities: updatedEntities({ ...state.entities }, apiResponse),
  };
};

const cartPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CART_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_CART_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_CART_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryListingsError: payload,
      };
    case ADD_CART_WINES_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default cartPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsById = (state, listings) => {
  const { entities } = state.CartPage;
  const resources = listings.map(listing => ({
    id: listing.id,
    type: 'listing',
    quantity: listing.quantity,
    isOwnListing: listing.isOwnListing,
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (winery wines listings data contains different info - e.g. exact location etc.)
export const addEntities = sdkResponse => ({
  type: ADD_CART_WINES_ENTITIES,
  payload: sdkResponse,
});

export const queryWineryListingsRequest = queryParams => ({
  type: FETCH_CART_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryWineryListingsSuccess = response => ({
  type: FETCH_CART_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryWineryListingsError = e => ({
  type: FETCH_CART_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const cartListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryWineryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, per_page: perPage };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addEntities(response));

      const newData = getWinesInCart();
      response.data.data.forEach(l => {
        l['quantity'] = newData.find(w => w.id === l.id.uuid).quantity;
        l['isOwnListing'] = newData.find(w => w.id === l.id.uuid).isOwnListing;
      });

      dispatch(queryWineryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryWineryListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => {
  const newData = getWinesInCart();
  const winesId = newData.map(wine => wine.id);

  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return cartListings({
    ...queryParams,
    ids: `${winesId.join(',')}`,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  });
};
