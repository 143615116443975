import React from 'react';
import css from './WineryCard.module.css';

import photo from './winery.jpg';
import oregonWinerySrc from '../../assets/oregonWinery.jpg';
import Bottle, { BottleRatingReview } from '../review/Bottle';
import NamedLink from '../NamedLink/NamedLink'
import { FilledHeartIcon, HeartIcon } from '../../assets';
import { average } from '../../util/average';

function WineryCard({winery, myFavs}) {
  return (
    <NamedLink key={winery._id} name="Winery" params={{slug: winery.slug, id: winery._id}}>
      <div className={css.youMightAlsoLikeCard}>
        <div className={css.favIcon}>
          {myFavs.has(winery._id) ? <FilledHeartIcon /> : <HeartIcon />}
        </div>
        <div className={css.youMightAlsoLikeCardHeader}>
          <img src={winery?.photo.length > 0 ? winery.photo[0].Location : winery?.state === 'Oregon' ? oregonWinerySrc : photo} alt="" />
        </div>
        <div className={css.youMightAlsoLikeCardBody}>
          <h2>{winery.name}</h2>
          <h5>{winery.region}</h5>
        </div>
        <div className={css.youMightAlsoLikeCardFooter}>
          <p>Sommly Score:</p>
          <div className={css.youMightAlsoLikeCardBottleReview}>
            <BottleRatingReview score={winery?.score !== undefined ? winery?.score : 0} uniqId={winery?._id} />
          </div>
        </div>
      </div>
    </NamedLink>
  )
}

export default WineryCard;
